import React, { Component } from 'react';
//import { Link } from "react-router-dom";
import { compose } from 'recompose';
import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';
import {RenderFullBrandPreview} from '../Coach/HomeCoach';
import { AccessCheckCreatorTrialPhase } from '../../constants/roles';
import { AccessCheckCoachCreator } from '../../constants/roles';

import TutorialPage from '../Onboarding/TutorialPage';
import Button from '@material-ui/core/Button';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import TodayCardsDesktopCoach from './TodayCardsDesktopCoach';
import ShowCurrentSubscription from '../Profile/ShowCurrentSubscription';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import SettingPlan from '../Profile/SettingPlan';
import CoachFeaturesActive from '../Coach/CoachFeaturesActive';

import CoachGroups from '../Coach/CoachGroups';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// MUI stuff
import Grid from "@material-ui/core/Grid";
//import Card from '@material-ui/core/Card';


const styles = theme => ({
	desktopPadding: {
	 	minHeight: 40,
	},
});


class TodayCoach extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;

    constructor(props) {
        super(props);
        
        this.state = {
            open: false,
        };
    } 

    componentDidMount() {
        this._isMounted = true;
        this.getCoachAdminLevel();
        //console.log(this.state)
        
    }
    componentWillUnmount(){
        this._isMounted = false;
    }


    getCoachAdminLevel(){
        var activeAdminStep = 0;
        var chosenStepType = undefined;
        if((this.context?.seatsMonthlyToGive || 0) + (this.context?.seatsToGive || 0) > 0){
            activeAdminStep = 4;
            chosenStepType= undefined;
        }
        else if((this.context.purchasedProduct?.owned && this.context.purchasedProduct?.creator ) || AccessCheckCreatorTrialPhase(this.context)){
            activeAdminStep = 2;
            chosenStepType= 'creator';
        }
        else if(this.context.programCode?.length > 2 && this.context.adminPrograms?.includes(this.context.programCode)){
            activeAdminStep = 1;
            chosenStepType= 'brand';
        }
        else if((this.context.dialogueCounter > 0 || this.context.dialogueStatus === "ended")){
            activeAdminStep = 1;
            chosenStepType= 'app';
        }
        else{
            activeAdminStep = 0;
            chosenStepType= 'app';
        }
        //console.log('activeAdminStep', activeAdminStep, 'chosenStepType', chosenStepType)
        this.setState({activeAdminStep: activeAdminStep, chosenStepType: chosenStepType})
    }

    render() {
        const {theme} = this.props;
        //console.log(this.state.reflectionInTimeRange,this.state.reflectionStatus )
        return (
            <div>
                {false &&
                    <TodayCardsDesktopCoach
                        defaultSet={this.props.defaultSet || undefined}
                        user={this.props.user}
                        leadershipCategories={this.props.leadershipCategories}
                        leadershipQualities={this.props.leadershipQualities}
                        coachView={true}
                        hideCoachView={() => this.setState({hideCoachView: true})}
                    />
                }
                {(this.context.dialogueCounter >= 2 || this.state.skipOnboarding) 
                ?
                    <div style={{padding:20, backgroundColor: theme.palette.backPaper}}>
                        <h1>
                            {this.context.dialogueCounter >=1 
                                ?   "How would you like to continue?"
                                :   "How would you like to start?"
                            }
                        </h1>
                        <Grid container spacing={2} direction='row' justify="space-between" style={{width: "100%"}}>
                            <Grid item xs={6}>
                                <CoachFeaturesActive
                                    user={this.context}
                                    chosenStepType={'teams'}
                                    unlocked={true}
                                    defaultSet={this.props.defaultSet || undefined}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CoachFeaturesActive
                                    user={this.context}
                                    chosenStepType={'app'}
                                    unlocked={true}
                                    defaultSet={this.props.defaultSet || undefined}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CoachFeaturesActive
                                    user={this.context}
                                    chosenStepType={AccessCheckCoachCreator(this.context) ? 'creator' : 'support'}
                                    unlocked={true}
                                    defaultSet={this.props.defaultSet || undefined}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <CoachFeaturesActive
                                    user={this.context}
                                    chosenStepType={'brand'}
                                    unlocked={true}
                                    defaultSet={this.props.defaultSet || undefined}
                                />
                            </Grid>
                        </Grid>
                    </div>
                :
                    <div style={{padding:20, backgroundColor: theme.palette.backPaper}}>
                        <h1>my AI Coach - Dashboard</h1>
                        <Grid container spacing={2} direction='row' justify="space-between" style={{width: "100%"}}>
                            <Grid item xs={12}>
                                <div style={{width: "100%", marginLeft: 60, marginRight: 40, textAlign: 'center'}}>
                                    <Tabs
                                        value={this.state.chosenStepType || "teams"}
                                        onChange={ (e, value) => this.setState({chosenStepType: value})}
                                        aria-label="My Groups"
                                        variant="scrollable"
                                        scrollButtons="auto"
                                        initialselectedindex={"teams"}
                                        style={{
                                            marginTop: 20,
                                            marginBottom: 0,
                                            marginLeft: -20,
                                            width: 'calc(100% + 40px)'
                                        }}
                                    >
                                        <Tab value="app" label="Enduser App"/>
                                        {AccessCheckCoachCreator(this.context)
                                        ?
                                            <Tab value="brand" label="Brand Settings"/>
                                        :
                                            <Tab value="plan" label="Subscription"/>
                                        }
                                        {AccessCheckCoachCreator(this.context)
                                        &&
                                            <Tab value="creator" label="Creator Tool"/>
                                        }
                                        <Tab value="teams" label="Teams Panel"/>
                                    </Tabs>
                                </div>
                                <div style={{...theme.cardBorder, marginTop: 0}}>
                                    <h2 style={theme.textSupportCenter}>Getting started</h2>
                                    <Stepper activeStep={this.state.activeAdminStep || 0} alternativeLabel>
                                        <Step onClick={() => this.setState({chosenStepType: 'app'})}>
                                            <StepLabel>Explore the App</StepLabel>
                                        </Step>
                                        {AccessCheckCoachCreator(this.context)
                                        ?
                                            <Step onClick={() => this.setState({chosenStepType: 'brand'})}>
                                                <StepLabel>Customize the App</StepLabel>
                                            </Step>
                                        :
                                            <Step onClick={() => this.setState({chosenStepType: 'plan'})}>
                                                <StepLabel>Activate FREE Trial</StepLabel>
                                            </Step>
                                        }
                                        {AccessCheckCoachCreator(this.context)
                                        &&
                                            <Step onClick={() => this.setState({chosenStepType: 'creator'})}>
                                                <StepLabel>Add your Content</StepLabel>
                                            </Step>
                                        }
                                        <Step onClick={() => this.setState({chosenStepType: 'teams'})}>
                                            <StepLabel>Invite your Users</StepLabel>
                                        </Step>
                                    </Stepper>
                                    {(this.state.chosenStepType === 'app')
                                    &&
                                        <>
                                            <h4 style={theme.textSupportCenter}>Explore the App</h4>
                                            <div style={theme.textSupportCenter}>
                                                Try out the endusers AI experience to understand the app and complete one full AI coaching chat yourself.
                                                <br/>(5 - 10 minutes)
                                            </div>
                                        </>
                                    }
                                    {(this.state.chosenStepType === 'brand')
                                    &&
                                        <>
                                            <h4 style={theme.textSupportCenter}>Set up your Brand</h4>
                                            <div style={theme.textSupportCenter}>
                                                You can set-up your brand and customize your user experience. 
                                                <br/>Access your affiliate links.
                                            </div>
                                        </>
                                    }
                                    {(this.state.chosenStepType === 'plan')
                                    &&
                                        <>
                                            <h4 style={theme.textSupportCenter}>Activate FREE Trial</h4>
                                            <div style={theme.textSupportCenter}>
                                                You can test the endusers app at any time. 
                                                <br/>However, to access the Teams Panel {AccessCheckCoachCreator(this.context) && "or the Creator Tool "} you need a subscription or a trial access.
                                            </div>
                                        </>
                                    }
                                    {(this.state.chosenStepType === 'creator')
                                    &&
                                        <>
                                            <h4 style={theme.textSupportCenter}>Customize the App</h4>
                                            <div style={theme.textSupportCenter}>
                                                Add your brand and create your own coaching programs (optionally) 
                                                <br/>and train the AI with your coaching questions.
                                            </div>
                                        </>
                                    }
                                    {(this.state.chosenStepType === 'teams')
                                    &&
                                        <>
                                            <h4 style={theme.textSupportCenter}>Invite your Users</h4>
                                            <div style={theme.textSupportCenter}>
                                                Create teams and group them into different organizations.
                                                <br/>Invite users as employees, clients or partners and monitor their coaching progress.
                                            </div>
                                        </>
                                    }
                                    {(AccessCheckCoachCreator(this.context) || this.state.chosenStepType !== undefined) && this.state.chosenStepType !== "plan"
                                    &&
                                        <Grid container spacing={2} direction='row' justify="space-between" style={{width: "100%", marginTop: 40}}>
                                            <Grid item xs={6}>
                                                App Setup:
                                                <div>
                                                    <CoachFeaturesActive
                                                        user={this.context}
                                                        chosenStepType={this.state.chosenStepType || undefined}
                                                        defaultSet={this.props.defaultSet || undefined}
                                                    />
                                                </div>
                                                {false && this.state.chosenStepType === 'creator'
                                                && 
                                                    <>
                                                        <br/><br/>Brand Preview:
                                                        <div style={{marginTop: 20, marginBottom: 20, borderStyle: 'solid', borderWidth: 'thin', borderRadius: theme.borderRadius, marginLeft:0, padding: 20, backgroundColor: theme.palette.backPaper, width:"100%"}}>
                                                            {this.props.defaultSet?.programMinPrograms?.length > 0 && this.context.adminPrograms?.some(programItem => this.props.defaultSet.programMinPrograms.includes(programItem))
                                                                ?   RenderFullBrandPreview(this, this.props.defaultSet, 'modeTitle')
                                                                :   RenderFullBrandPreview(this, {}, 'modeTitle')
                                                            }
                                                        </div>
                                                    </>
                                                }
                                            </Grid>
                                            <Grid item xs={6}> 
                                                {this.state.chosenStepType !== "app"
                                                ?
                                                    <div style={{paddingTop: 0}}>
                                                        {(true || (this.context.purchasedProduct?.owned && this.context?.purchasedProduct?.creator))
                                                        ?
                                                            <ShowCurrentSubscription 
                                                                user={this.context} 
                                                                coach={true}
                                                            />
                                                        :
                                                            <SettingPlan 
                                                                user={this.props.user}
                                                                coach={true}
                                                            />
                                                    }
                                                        </div>
                                                :
                                                    <div>
                                                        Features:
                                                        <br/><br/>
                                                        <strong>
                                                            Try out the endusers AI experience to understand the app and complete one full AI coaching chat yourself.
                                                        </strong>
                                                        <br/><br/>
                                                        <div>
                                                            <li>Finish a 5-10 minute AI coaching chat</li>
                                                            <li>Set up your personal coaching plan example</li>
                                                            <li>Explore articles and learning content</li>
                                                            <li>Follow-up on personal goals</li>
                                                            <li>Check out the self-assessment questionnaires</li>
                                                        </div>
                                                    </div>
                                                }
                                            </Grid>
                                        </Grid>
                                    }
                                </div>
                            </Grid>
                            {this.state.chosenStepType === "plan"
                            &&
                                <>
                                    <Grid item xs={6}>
                                        Shop:
                                        <SettingPlan 
                                            showCurrentSubscription={false}
                                            user={this.props.user}
                                            coach={true}
                                        />
                                    </Grid>
                                    <Grid item xs={6}> 
                                        Your Subscription:
                                        <ShowCurrentSubscription 
                                            user={this.context} 
                                            coach={true}
                                        />
                                    </Grid>
                                </>
                            }
                            <Grid item xs={12}>
                                <Button 
                                    onClick={() => {
                                        if (this.props?.defaultSet?.programSupportURL?.length>10){ 
                                            window.open(this.props.defaultSet?.programSupportURL , "_blank")
                                        }
                                        else{
                                            window.open("https://help.rocky.ai", "_blank")
                                        }
                                    }}
                                >
                                    Detailed Help
                                </Button>
                                {false &&
                                <Button
                                    variant="outlined" color="secondary"
                                    onClick={() => {this.setState({showTutorial : !(this.state.showTutorial) })}}
                                >
                                    <span>
                                        <HelpOutlineIcon style={theme.inlineIconLeft}/>
                                        {this.state.showTutorial ? "Hide Tutorial" : "Show Tutorial"}
                                    </span>
                                </Button>
                                }
                                {this.state.showTutorial &&
                                    <div style={{marginTop: 20, marginBottom: 20, borderStyle: 'solid', borderWidth: 'thin', borderRadius: theme.borderRadius, marginLeft:0, padding: 20, borderColor: theme.palette.secondary.light, backgroundColor: theme.palette.backPaper, width:"100%"}}>
                                        <TutorialPage 
                                            showDesktop={true}
                                            showStepper={false}
                                            addDefaults={true}
                                            badgeFamily={"teams"} 
                                            handleClose={undefined}
                                            handleScrollTop={() => { if(this.dialogRef!==undefined  && this.dialogRef!==null &&  this.dialogRef.scrollTop!==undefined ) this.dialogRef.scrollTop = 0}}
                                        />
                                    </div>
                                }
                            </Grid>
                            <Grid item xs={12}>
                                {this.context.roles?.includes("REMOTECOACH") && this.state.chosenStepType === undefined
                                    &&
                                        <CoachGroups
                                            defaultSet={this.props.defaultSet || undefined}
                                            leadershipCategories={this.props.leadershipCategories}
                                            leadershipQualities={this.props.leadershipQualities} 
                                            user={this.props.user}
                                        />
                                }
                            </Grid>
                        </Grid>
                        {/***
                        <CoachUsersWindows 
                            defaultSet={this.props.defaultSet || undefined}
                            leadershipCategories={leadershipCategories}
                            leadershipQualities={leadershipQualities} 
                            user={this.props.user}/>
                        */}
                    </div>
                }
            </div>
        )
    }
    
}

export default compose(
    withStyles(styles, { withTheme: true})
)(TodayCoach);