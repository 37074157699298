import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import * as ROUTES from '../../constants/routes';
import DrawerBrandOverview from '../Coach/DrawerBrandOverview';
import { Link } from "react-router-dom";

// core
import { withFirebase } from '../Firebase';
import { AuthUserContext } from '../Session';
import TutorialPage from '../Onboarding/TutorialPage';
import {BestForProfile} from '../Profile/RoleOverview';

// Modules
import CoachUsersWindows from '../Coach/CoachUsersWindows';
import CoachUsers from '../Coach/CoachUsers';
import CoachGroups from '../Coach/CoachGroups';
import CoachSubscriptions from '../Coach/CoachSubscriptions';
import LoadingProgress from '../Modules/LoadingProgress';
import SnackbarInfo from '../Modules/SnackbarInfo';
//import { DataCacheRead, DataCacheWrite} from "../Modules/DataCache";
import Button from '@material-ui/core/Button';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import PostAddIcon from '@material-ui/icons/PostAdd';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ForumIcon from '@material-ui/icons/Forum';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import HomeIcon from '@material-ui/icons/Home';
import RockyIcon from '../Modules/RockyIcon';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import PublicIcon from '@material-ui/icons/Public';
import logo from "../../images/rocky-logo-square.png";
import BuildIcon from '@material-ui/icons/Build';
///// Manage the navigation bar appear
import VizSensor from 'react-visibility-sensor';
import pixel from "../../images/pixel.png";

import Card from '@material-ui/core/Card';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';

//import {IsMobileDevice} from '../Modules/IsMobileDevice';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});



class HomeCoach extends Component {
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        var startTab = "User Insights";
        var defaultState = {
            loading: true,
            value: startTab,
            user: null,
            headerVisible: true,
        };
        this.state = defaultState;
    }
    handleChangeTab = (event, newValue, openDialogueVariant=undefined) => {
        if(newValue === "Home"){
            this.props?.history?.push(ROUTES.HOME)
        }
        else{
            try{
                document.body.scrollTop = document.documentElement.scrollTop = 0;
                //if(this.dialogRef !== undefined && this.dialogRef.scrollTop !== undefined ) this.dialogRef.scrollTop  = 0;
            }
            catch (err) {
                console.log('No scroll to top');
            }
            this.setState({
                value: newValue,
                [newValue] : true,
                openDialogueVariant
            });
        }
    }
    componentDidMount() {
        var defaultState = { loading: false }
        if(this.props.location!==undefined && this.props.location.state !== undefined){
            Object.keys(this.props.location.state).map(key => {
                //console.log(key, this.props.location.state[key])
                defaultState[key] = this.props.location.state[key];
                return true
            })
            //console.log('props', this.props, this.props.location)
            //console.log('state', defaultState)
        }
        this.setState(defaultState);
    }

    componentWillUnmount() {
        //this.unsubscribeDialogues();
        this.setState({ loading: true });
        //window.removeEventListener('scroll', this.handleScroll);
    }


    render() {
        const { theme } = this.props;
        const { loading,  value } = this.state;
        const {user, leadershipCategories, leadershipQualities, screen} = this.props;
        //const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
        //const weekdays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]
        //const today = new Date(Date.now());
        //console.log(this.state)
        if(user !== null ){
            //console.log("new user", user)
            return(
                <Grid container direction="row">
                    <DrawerBrandOverview 
                        user={this.context}
                        defaultSet={this.props.defaultSet || undefined}
                        leadershipCategories={leadershipCategories}
                        leadershipQualities={leadershipQualities}
                        bottomPosition={true}
                    /> 
                    <Grid item xs={screen==="desktop" ? 2 : false} >
                        {screen==="desktop"
                            &&
                            <Card style={theme.CardDesktopLeft}>
                                <Card style={theme.CardNaviLeft}>
                                    <div 
                                        style={{...theme.cardBorder, backgroundColor: theme.palette.themeAlertOrange+'0F', borderColor: theme.palette.themeAlertOrange, textAlign: 'center'}}
                                    >
                                        <p><strong>
                                            <RecordVoiceOverIcon style={theme.inlineIconLeft}/> Teams Panel
                                        </strong></p>
                                        <span style={theme.textSupport}>
                                            Manage users, clients & teams
                                        </span>
                                    </div>
                                    {false
                                    &&
                                        <div style={{width: '100%', textAlign: 'center', marginBottom: 20}}>
                                            <Button 
                                                variant="outlined" color="secondary"
                                                onClick={() => {
                                                    this?.props?.history?.push(ROUTES.HOME)
                                                }}
                                            >
                                                <span>
                                                    <HomeIcon style={theme.inlineIconLeft}/>
                                                    Home
                                                </span>
                                            </Button>
                                        </div>
                                    }
                                    {RenderCoachTabs(this, "vertical")}
                                </Card>
                            </Card>
                        }
                    </Grid>
                    <Grid item xs={screen==="desktop" ? 10 : 12} style={{paddingLeft: screen==="desktop" ? 20 : 0}}>
                        {loading
                            ?
                                <div>
                                    <LoadingProgress 
                                        defaultSet={this.props.defaultSet}
                                        type={'LoadingPageAnimated'} loadingText={"loading Teams Panel"} />
                                </div>
                            :
                                <Card style={screen==="desktop"? theme.CardDesktopMain : theme.CardAppMain}>
                                    <VizSensor 
                                        partialVisibility={"top"}
                                        onChange={(isVisible) => { 
                                            if(screen!== "desktop" && isVisible && this._isMounted) { 
                                                this.setState({headerVisible: true});
                                                //console.log('visible top cart')
                                            }
                                            else if (screen!== "desktop" && this._isMounted){
                                                this.setState({headerVisible: false});
                                                //console.log('hding top cart')
                                            }
                                        }}>
                                        <div style={{height: 1}}>  <img src={ pixel} alt=''/></div>
                                    </VizSensor>
                                    <Card style={{
                                            ...(this.props.screen === 'desktop' ? theme.CardDesktopTop : theme.CardTop), 
                                            position: this.state.headerVisible? 'relative' : 'fixed',
                                            zIndex: this.state.headerVisible? 0 : 100,
                                            top: 0,
                                            opacity: this.state.headerVisible? '1.0' : '0.94',
                                        }}>
                                        {this.state.headerVisible
                                            ?   
                                                screen==="desktop"
                                                ?
                                                    <h4 style={theme.CardContent.titleText}>
                                                        <RecordVoiceOverIcon style={theme.inlineIconLeft}/>&nbsp;Teams Panel
                                                    </h4>
                                                :   <h1 style={theme.CardContent.titleText}><RecordVoiceOverIcon />&nbsp;{value}</h1>
                                            :   <div style={theme.textMonthCenter}><strong>{value}</strong></div>
                                        }
                                        {RenderCoachTabs(this, "horizontal")}
                                    </Card>
                                    <Card style={{
                                            ...theme.CardContent, 
                                            backgroundColor: theme.palette.backPaper,
                                            borderRadius:  theme.borderRadius,
                                            marginTop: this.state.headerVisible? 0 : 200,
                                            paddingTop: 20,
                                            paddingBottom: this.state.headerVisible? 0 : '70vh',
                                        }} >
                                        
                                        {user !== null && leadershipQualities.length > 0
                                        ?
                                            screen==="desktop"
                                            ?
                                                <div>
                                                    <div value={"User Insights"} index={0} hidden={value !== "User Insights"}>
                                                        {(value==='User Insights' || this.state['User Insights']) &&
                                                            <CoachUsersWindows 
                                                                defaultSet={this.props.defaultSet || undefined}
                                                                leadershipCategories={leadershipCategories}
                                                                leadershipQualities={leadershipQualities} 
                                                                user={user}
                                                                openDialogueVariant={this.state.openDialogueVariant}
                                                                />
                                                        }
                                                        {(value==='User Insights' || this.state['User Insights']) &&
                                                            <SnackbarInfo 
                                                                alertText = 'Here you can read the AI chats of your clients.'
                                                                alertName = 'userchatsintro'
                                                                userId = {this.context.uid}
                                                                />
                                                        }
                                                    </div>
                                                    <div value={"Manage Teams"} index={1} hidden={value !== "Manage Teams"}>
                                                        {(value==='Manage Teams' || this.state['Manage Teams']) &&
                                                            <CoachGroups 
                                                                defaultSet={this.props.defaultSet || undefined}
                                                                leadershipCategories={leadershipCategories}
                                                                leadershipQualities={leadershipQualities} 
                                                                variant = 'groupsOnly'
                                                                user={user}
                                                                openDialogueVariant={this.state.openDialogueVariant}
                                                                />
                                                        }
                                                        {(value==='Manage Teams' || this.state['Manage Teams']) &&
                                                            <SnackbarInfo 
                                                                alertText = 'Set up user groups and manage sub-groups.'
                                                                alertName = 'coachadminintro'
                                                                userId = {this.context.uid}
                                                                />
                                                        }
                                                    </div>
                                                    <div value={"Manage Users"} index={3} hidden={value !== "Manage Users"}>
                                                        {(value==='Manage Users' || this.state['Manage Users']) &&
                                                            <CoachGroups 
                                                                defaultSet={this.props.defaultSet || undefined}
                                                                leadershipCategories={leadershipCategories}
                                                                leadershipQualities={leadershipQualities} 
                                                                variant = 'usersOnly'
                                                                user={user}
                                                                openDialogueVariant={this.state.openDialogueVariant}
                                                                />
                                                        }
                                                        {(value==='Manage Users' || this.state['Manage Users']) &&
                                                            <SnackbarInfo 
                                                                alertText = 'Add new clients, coaches and users.'
                                                                alertName = 'coachadminintro'
                                                                userId = {this.context.uid}
                                                                />
                                                        }
                                                    </div>
                                                    <div value={"Manage Seats"} index={4} hidden={value !== "Manage Seats"}>
                                                        {(value==='Manage Seats' || this.state['Manage Seats']) &&
                                                            <CoachSubscriptions
                                                                defaultSet={this.props.defaultSet || undefined}
                                                                leadershipCategories={leadershipCategories}
                                                                leadershipQualities={leadershipQualities} 
                                                                user={user}
                                                                openDialogueVariant={this.state.openDialogueVariant}
                                                                />
                                                        }
                                                        {(value==='Manage Seats' || this.state['Manage Seats']) &&
                                                            <SnackbarInfo 
                                                                alertText = 'Pay here for your client accounts.'
                                                                alertName = 'paymentcoachintro'
                                                                userId = {this.context.uid}
                                                                />
                                                        }
                                                    </div>
                                                    
                                                </div>
                                            :
                                                <div>
                                                    <div value={"User Insights"} index={0} >
                                                        <CoachUsers
                                                            defaultSet={this.props.defaultSet || undefined}
                                                            leadershipCategories={leadershipCategories}
                                                            leadershipQualities={leadershipQualities} 
                                                            user={user}
                                                            openDialogueVariant={this.state.openDialogueVariant}
                                                            />
                                                    </div>
                                                </div>
                                        :
                                            <div>
                                                <p >We have a problem with your page access!</p>
                                            </div>
                                        }
                                    </Card>
                                    <Grid item xs={12}>
                                        <Button 
                                            onClick={() => {
                                                if (this.props?.defaultSet?.programSupportURL?.length>10){ 
                                                    window.open(this.props.defaultSet?.programSupportURL , "_blank")
                                                }
                                                else{
                                                    window.open("https://help.rocky.ai", "_blank")
                                                }
                                            }}
                                        >
                                            Detailed Help
                                        </Button>
                                        {false &&
                                        <Button
                                            variant="outlined" color="secondary"
                                            onClick={() => {this.setState({showTutorial : !(this.state.showTutorial) })}}
                                        >
                                            <span>
                                                <HelpOutlineIcon style={theme.inlineIconLeft}/>
                                                {this.state.showTutorial ? "Hide Tutorial" : "Show Tutorial"}
                                            </span>
                                        </Button>
                                        }
                                        {this.state.showTutorial
                                        &&
                                            <div style={{marginTop: 20, marginBottom: 20, borderStyle: 'solid', borderWidth: 'thin', borderRadius: theme.borderRadius, marginLeft:0, padding: 20, borderColor: theme.palette.secondary.light, backgroundColor: theme.palette.backPaper, width:"100%"}}>
                                                <TutorialPage 
                                                    showDesktop={true}
                                                    showStepper={false}
                                                    addDefaults={true}
                                                    badgeFamily={"teams"} 
                                                    handleClose={undefined}
                                                    handleScrollTop={() => { if(this.dialogRef!==undefined  && this.dialogRef!==null &&  this.dialogRef.scrollTop!==undefined ) this.dialogRef.scrollTop = 0}}
                                                />
                                            </div>
                                        }
                                    </Grid>
                                </Card> 
                        }   
                </Grid>
            </Grid>
            ); // end return
        }// end if user
        else {
            return(
                <Card style={theme.CardContent}>
                    <p style={theme.CardContent.titleText}>Please wait a moment</p>
                </Card>
            )// end 2nd return
        } // end else
    }// end render

} // end class

export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(HomeCoach);

export function RenderCoachTabs(that, orientation = "horizontal") {
    var value = that.state.value;
    if (value === undefined) {value = 'Home'}
    const { theme } = that.props;
    const { user, leadershipQualities } = that.props;
    if((window.location?.hostname?.includes('coach.rocky.ai') || window.location?.href?.includes('/coach')))
        return(
            <>
                {orientation === 'vertical'
                &&
                    <div style={theme.textSettings}>
                        {user?.seatsMonthlyToGive > 0
                        && 
                            <strong style={theme.textSettings}>
                                <span style={theme.textSettings.settingsTextValue}>{user?.seatsMonthlyToGive || 0}</span>
                                <span >Monthly Seats Available:</span>
                                <br/>
                            </strong>
                        }
                        {
                            <strong style={theme.textSettings}>
                                <span style={theme.textSettings.settingsTextValue}>{user?.seatsToGive || 0}</span>
                                <span >Extra Seats Available (Not Time-Bound):</span>
                                <br/>
                            </strong>
                        }
                    </div>
                }
                {orientation !== 'vertical' && user !== null && leadershipQualities!== undefined && leadershipQualities.length > 0
                    &&
                    <Tabs
                        value={value}
                        orientation={orientation}
                        onChange={(event, value) => HandleChangeTabs(that, event, value)}
                        aria-label="User Insights"
                        variant="scrollable"
                        scrollButtons="auto"
                        initialselectedindex={'User Insights'}
                        style={{
                            marginTop: 20,
                            marginBottom: 0,
                            marginLeft: orientation === 'vertical' ? -20 : 0,
                            width: 'calc(100% + 40px)'
                        }}
                        ref={el => that.containerLine = el}
                    >
                        {false 
                        &&
                            <Tab label="Home" value="Home"/>
                        }
                        <Tab value={"User Insights"} 
                            label=
                            {
                                <span>
                                    <ForumIcon style={theme.inlineIconLeft}/>
                                    User Insights</span>
                            }
                        />
                        <Tab value={"Manage Teams"}
                            label=
                            {
                                <span>
                                    <GroupWorkIcon style={theme.inlineIconLeft}/>
                                    Manage Teams</span>
                            }
                        />
                        <Tab value={"Manage Users"}
                            label=
                            {
                                <span>
                                    <PeopleAltIcon style={theme.inlineIconLeft}/>
                                    Manage Users</span>
                            }
                        />
                        <Tab value={"Manage Seats"} 
                            label=
                            {
                                <span>
                                    <ShoppingBasketIcon style={theme.inlineIconLeft}/>
                                    Manage Seats</span>
                            }
                        />
                        
                    </Tabs>              
                }
                {orientation === 'vertical' 
                &&
                    <div>
                        <br/>
                        <>
                            <Link to={ROUTES.HOME}>
                                <span><HomeIcon style={theme.inlineIconLeft}/> Home</span>
                            </Link>
                            <hr/>
                            <Link to={ROUTES.COACH} onClick={() => HandleChangeTabs(that, undefined, 'Manage Users')}>
                                <span><RecordVoiceOverIcon style={theme.inlineIconLeft}/> Teams Panel</span>
                            </Link>
                            {that.state?.selectedCategory?.length > 2 
                            ?
                                <Link to={ROUTES.COACH} onClick={() => {
                                    that.setState({selectedCategory: undefined, selectedQuality: undefined})
                                    HandleChangeTabs(that, undefined, 'Manage Users')
                                }}
                                >
                                    <li style={{paddingTop: 8, paddingLeft: 5, whiteSpace: 'nowrap'}}> {value} </li>
                                </Link>
                            : 
                                <li style={{paddingTop: 8, paddingLeft: 5, whiteSpace: 'nowrap'}}> {value} </li>
                            }
                        </>
                        <h4 style={theme.CardContent.titleText}>Quick Actions</h4>
                        <Button 
                            variant="outlined" color="secondary"
                            onClick={() => HandleChangeTabs(that, undefined, 'Manage Users', 'inviteExpended')}
                        >
                            <span>
                                <PersonAddIcon style={theme.inlineIconLeft}/>
                                Invite New User
                            </span>
                        </Button>
                        <br/>
                        <br/>
                        <Button 
                            variant="outlined" color="secondary"
                            onClick={() => HandleChangeTabs(that, undefined, 'Manage Teams')}
                        >
                            <span>
                                <PostAddIcon style={theme.inlineIconLeft}/>
                                Create New Team
                            </span>
                        </Button>
                        <br/>
                        <br/>
                        <Button 
                            variant="outlined" color="secondary"
                            onClick={() => HandleChangeTabs(that, undefined,'Manage Seats')}
                        >
                            <span>
                                <AddShoppingCartIcon style={theme.inlineIconLeft}/>
                                Purchase Seats
                            </span>
                        </Button>
                    </div>
                }
            </>
        )
    else return(
        <div>
            {window?.location?.hash?.includes('/program') 
                ?   
                    <>
                        <div 
                            style={{...theme.cardBorder, backgroundColor: theme.palette.secondary.light+'0F', textAlign: 'center'}}
                        >
                            <p><strong>
                                <img 
                                    src={that.props?.defaultSet?.programLogo?.includes('http') ? that.props.defaultSet.programLogo : logo} 
                                    alt={'App'}
                                    style={{maxWidth: 20, marginRight: 6, marginBottom: -4}}
                                />Enduser App
                            </strong></p>
                            <span style={theme.textSupport}>
                                Explore AI chat, quests, goals
                            </span>
                        </div>
                        <Link to={ROUTES.HOME}>
                            <span><HomeIcon style={theme.inlineIconLeft}/> Home</span>
                        </Link>
                        <Link to={ROUTES.PROGRAM}>
                            <li style={{paddingTop: 8, paddingLeft: 10, whiteSpace: 'nowrap', color: theme.palette.black}}> {"App View"} </li>
                        </Link>
                        <hr/>
                        <Link to={ROUTES.COACH}>
                            <span><RecordVoiceOverIcon style={theme.inlineIconLeft}/> Teams Panel</span>
                        </Link>
                        <hr/>
                        <Link to={ROUTES.COACH_CONTENT}>
                            <span><AddShoppingCartIcon style={theme.inlineIconLeft}/> Creator Tool</span>
                        </Link>
                        <hr/>
                        <Link to={ROUTES.COACH_ADMIN}>
                            <span><BuildIcon style={theme.inlineIconLeft}/> App Settings</span>
                        </Link>
                    </>
                :   ""
            }
            {false && 
                <p style={{textAlign: 'center'}}><strong>
                    <HomeIcon style={theme.inlineIconLeft}/>
                    Home
                </strong></p>
            }
            {window?.location?.hash?.includes('/home') 
                ?   <span><HomeIcon style={theme.inlineIconLeft}/> Admin Home</span>
                :   <span><br/><br/></span> 
            }
            {false && window?.location?.hash?.includes('/program') 
            &&
                <>
                    <div style={{width: '100%', textAlign: 'center'}}>
                        <Button 
                            variant="outlined" color="secondary"
                            onClick={() => {
                                that?.props?.history?.push(ROUTES.HOME)
                            }}
                        >
                            <span>
                                <HomeIcon style={theme.inlineIconLeft}/>
                                Home
                            </span>
                        </Button>
                    </div>
                </>
            }
            {false && !(window?.location?.hash?.includes('/program') )
            &&
                <>
                    <div 
                        style={{...theme.cardBorder, backgroundColor: theme.palette.secondary.light+'0F', textAlign: 'center'}}
                        onClick={() => {
                            that?.props?.history?.push(ROUTES.PROGRAM)
                        }}
                    >
                        <p><strong>
                            <img 
                                src={that.props?.defaultSet?.programLogo?.includes('http') ? that.props.defaultSet.programLogo : logo}
                                alt={'App'}
                                style={{maxWidth: 20, marginRight: 6, marginBottom: -4}}
                            />Enduser App
                        </strong></p>
                        <span style={theme.textSupport}>
                            Explore AI chat, modules, goals
                        </span>
                        <br/>
                        <br/>
                        <Button 
                            variant="outlined" 
                            color="secondary"
                        >
                            <span>
                                <RockyIcon style={theme.inlineIconLeft}/>
                                App
                            </span>
                        </Button>
                    </div>
                </>
            }
            {false &&
                <div 
                    style={{...theme.cardBorder, backgroundColor: theme.palette.themeAlertOrange+'0F', borderColor: theme.palette.themeAlertOrange, textAlign: 'center'}}
                    onClick={() => {
                        that?.props?.history?.push(ROUTES.COACH)
                    }}
                >
                    <p><strong>
                        <RecordVoiceOverIcon style={theme.inlineIconLeft}/> Teams Panel
                    </strong></p>
                    <span style={theme.textSupport}>
                        Manage users, clients & teams
                    </span>
                    <br/>
                    <br/>
                    <Button 
                        variant="outlined" 
                        color="secondary"
                        style={{borderColor: theme.palette.themeAlertBlue}}
                    >
                        <span>
                            <RecordVoiceOverIcon style={theme.inlineIconLeft}/>
                            Users
                        </span>
                    </Button>
                </div>
            }
            {false && (that?.context?.purchasedProduct?.creator === true || that?.context?.roles?.includes("COACHADMIN") || that?.context?.roles?.includes("ADMIN"))
            &&
                <div 
                    style={{...theme.cardBorder, backgroundColor: theme.palette.themeAlertRed+'0F', borderColor: theme.palette.themeAlertRed, textAlign: 'center'}}
                    onClick={() => {
                        that?.props?.history?.push(ROUTES.COACH_CONTENT)
                    }}
                >
                    <p><strong>
                        <AddShoppingCartIcon style={theme.inlineIconLeft}/> Creator Tool
                    </strong></p>
                    <span style={theme.textSupport}>
                        Manage content, programs & quests
                    </span>
                    <br/>
                    <br/>
                    <Button 
                        variant="outlined" 
                        color="secondary"
                        style={{borderColor: theme.palette.themeAlertRed}}
                    >
                        <span>
                            <AddShoppingCartIcon style={theme.inlineIconLeft}/>
                            Creator
                        </span>
                    </Button>
                </div>
            }
            <SnackbarInfo 
                alertText = {"The Admin Tool and CMS Panel opens in a new Browser Tab"}
                openOnClick = {true}
                alertOpenEvent = {that.state.openAlert || false}
                closeHandler = {() => {that.setState({openAlert: false})}}
            />
        </div>
    )
}

export function RenderFullBrandPreview(that, defaultSet, mode='modeFull'){
    if(that){
        const {theme} = that?.props;
        //console.log('defaultSet', defaultSet)
        if(defaultSet){
            return(
                <div>
                    {defaultSet !== undefined && defaultSet.programTitle !== undefined
                        ?   (
                            <div ref={that.dialogRef}>
                                <h4>
                                    {defaultSet?.programLogo?.includes("http")
                                        ?
                                            <img 
                                                style={{
                                                    maxWidth:60,
                                                    backgroundColor : 'transparent',
                                                    margin: 4,
                                                    maxHeight: 60, 
                                                    height: 'auto', 
                                                    verticalAlign: 'middle',
                                                    borderRadius: theme.borderRadius,
                                                }} 
                                                src={defaultSet.programLogo}
                                                alt="My AI Coach" 
                                            />
                                        :   ''
                                    }
                                    {defaultSet.programTitle}
                                </h4>
                                <h2 style={theme.CardContentItem.TextTitle}>
                                    {defaultSet.programSubTitle}
                                </h2>
                                <div style={{width: '100%', textAlign: 'center'}}>
                                    {defaultSet?.programImage?.includes("http")
                                        &&
                                        <img
                                            src={defaultSet.programImage}
                                            style={{...theme.logo, width: '100%', maxWidth: 600}} 
                                            alt="AI Coaching Chatbot" 
                                        />
                                    }
                                </div>
                                {defaultSet.programLifeStages !== undefined 
                                    &&
                                    <div style={theme.textSupport}>
                                        {BestForProfile(undefined, defaultSet)}
                                   </div>
                                }
                                {(mode === "modeFull" || mode === "")
                                    &&
                                    <div>
                                        <span style={theme.CardContentItem.TextSubTitle}>
                                            {defaultSet.definition}
                                            {
                                            //FindTextValueInDict(this.props.firebase, this.state.leadershipCategories, this.state.chosenCategory, 'definition')
                                            }
                                        </span>
                                    </div>
                                }
                            </div>
                            )
                        :
                            <>
                                <strong style={theme.textSupportCenter}>Add your own brand and customize the app in the 'Creator Tool'</strong>
                                <br/><br/>
                                {false &&
                                <div style={{textAlign: "right", marginRight: 20}}>
                                    <Button 
                                        variant="outlined" color="secondary"
                                        onClick={() => {that?.props?.history?.push(ROUTES.COACH_CONTENT)}}
                                    >
                                        <span>
                                            <AddShoppingCartIcon style={theme.inlineIconLeft}/>
                                            Open Creator Tool
                                        </span>
                                    </Button>
                                </div>
                                }
                            </>
                    }
                    {(mode === "modeFull" || mode === "")
                    &&
                        <>
                            {defaultSet.programDescription !== undefined  && defaultSet.programDescription !== ''
                                &&
                                <div 
                                    className={theme.innerHTML}
                                    onClick = {(event) => {
                                        try{
                                            const link = event.target.getAttribute('href');
                                            if(link !== undefined && link !== null && link !== ""){
                                                event.preventDefault();
                                                window.open(link, "_blank")
                                            }
                                        }
                                        catch(e){
                                            console.log('No Link', e)
                                        }
                                    }}
                                    dangerouslySetInnerHTML={{ __html: defaultSet.programDescription}} />
                            }
                            <div style={theme.logoContainer}>
                                {defaultSet.programBrand?.includes("http")
                                    &&
                                    <img
                                        onClick={() => {
                                            if (defaultSet.programSupportURL?.length>10){ window.open((defaultSet?.programSupportURL ), "_blank")}
                                            else{ void(0)}
                                        }}
                                        src={defaultSet.programBrand}
                                        style={theme.logo} 
                                        alt="AI Coaching Chatbot" 
                                    />
                                }
                                {defaultSet.programCompany?.length > 10
                                    &&
                                    <div
                                        onClick={() => {
                                            if (defaultSet.programSupportURL?.length>10){ window.open((defaultSet?.programSupportURL ), "_blank")}
                                            else{ void(0)}
                                        }}
                                    >
                                        {defaultSet.programCompany}
                                    </div>
                                }
                            </div>
                        </>
                    }
                </div>
            )
        }
    }
}

function HandleChangeTabs(that, event, value, openDialogueVariant=undefined){
    if(that.handleChangeTab !== undefined){
        that.handleChangeTab(event, value, openDialogueVariant); 
    }
    else{
        var state = {
            value: value,
            [value] : true
        }
        if(openDialogueVariant?.length > 1){
            state[openDialogueVariant] = true;
        }
        //console.log('handle change 3  state', state)
        //that.props.history.push(ROUTES.COACH, state);
        /***
         */
        that.props.history.push({
            pathname: ROUTES.COACH,
            state: state
        });
    }
}
