import React, { Component } from 'react';
import { compose } from 'recompose';
import withStyles from '@material-ui/core/styles/withStyles';
import PropTypes from 'prop-types';

import { AuthUserContext } from '../../Session';
import { withFirebase } from '../../Firebase';

import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
//import Grid from '@material-ui/core/Grid';
//import MenuItem from '@material-ui/core/MenuItem';
//import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
//import Switch from "react-switch";

import IconButton from '@material-ui/core/IconButton';
//import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@material-ui/core/Tooltip';
import BorderColorTwoToneIcon from '@material-ui/icons/BorderColorTwoTone';

const styles = theme => ({
    grow: {
        flexGrow: 1,
    },
});


class SettingContentActive extends Component {
    static contextType = AuthUserContext;

    constructor(props) {
        super(props);
        //console.log('constructor')
        this.state = {
            openSetting: false,
            // default value for each param
            defaultValue: false,
        };
    }

    // Username Handling
    handleSettingOpen = () => {
        this.props.params.map((p) => {
            if(this.props.content[p]!== undefined){
                return this.setState({[p] : this.props.content[p]});
            }
            else{
                return this.setState({[p] : this.state.defaultValue});
            }
            
        })
        this.setState({
            openSetting: true,
        });
    }
    handleSettingChange = (param, event) => {
        if(event.target.checked !== undefined){
            //console.log('before', param, event, event.target.checked)
            this.setState({
                [param]: event.target.checked
            });
        }
        //console.log('event', event.target.checked)
    }
    handleSettingChangeSave = (param, event) => {
        if (this.props.access === undefined || this.props.access === true) {
            if(event.target.checked !== undefined){
                //console.log('save soon', param, event.target.checked, this.state)
                this.setState({
                    [param]: event.target.checked
                }, () => this.handleSettingSubmit(param))
            }
        }
    }
    handleSettingClose = () => {
        this.setState({
            openSetting: false,
        });
        this.props.params.map((p) => {
            //console.log('parmas ', p)
            //console.log('parmas stateclose', this.state[p])
            //console.log('parmas props close', this.props.content[p])
            if(this.state[p] !== this.props.content[p] && this.state[p] !== undefined){
                this.setState({[p] : this.state.defaultValue});
            }
            return ''
        })
    }
    handleSettingSubmit = (paramFocus = undefined ) => {
        //console.log('state', this.state, paramFocus)
        this.props.params.map((p) => {
            if(paramFocus === undefined || paramFocus === p){
                //console.log('p', this.state)
                if(this.state[p] !== null && this.state[p] !== "" && this.state[p] !== undefined && this.state[p] !== this.props.content[p] ){
                    if(this.props.setUpdate){
                        this.props.dbUpdateLink.set({
                            [p]: this.state[p]
                        }, {merge:true});
                        //console.log('updatefirst', this.state[p], p)
                    }
                    else{
                        var updateSet = { [p]: this.state[p] };
                        if(this.props.updateExtraChange !== undefined && typeof this.props.updateExtraChange === 'object'){
                            Object.assign(updateSet, this.props.updateExtraChange)
                        }
                        if(this.props.dbUpdateLink !== undefined){
                            this.props.dbUpdateLink.set(updateSet, {merge:true});
                        }
                        //console.log('updateSet', updateSet)
                        if(this.props.updateState !== undefined){
                            this.props.updateState(updateSet);
                        }
                    }
                }
            }
            return '';
        })

        this.handleSettingClose();
    }

    render() {
        const { content, params, title, labels } = this.props;
        const { theme, classes } = this.props;
        //console.log('updated props', this.props)

        return (
            content
                ?
                <div className={classes.grow}>
                <Card style={theme.cardAdminSettings}>
                    <Grid container>
                        <Grid item xs={9} ><p style={{color:"darkgreen"}}>{title}</p>  </Grid>
                        <Grid item xs={1}>  </Grid>
                        <Grid item xs={2} style={{textAlign: 'right', marginTop: -10}}>
                            {(this.props.access === undefined || this.props.access === true)  &&
                                <Tooltip title={'Edit/Change'}>
                                    <IconButton onClick={this.handleSettingOpen}> 
                                        <BorderColorTwoToneIcon fontSize="small" style={{color:"red"}}/>
                                    </IconButton>
                                </Tooltip>
                            }
                        </Grid>
                    </Grid>
                    {
                        params.map((p, index) => (
                            <p style={theme.textSettings} key={"current_"+p}>
                                <span>
                                {content[p]!== undefined
                                    ?   content[p]===true
                                        ?   <CheckBoxIcon style={{ color:"green" }} 
                                                onClick = {() => this.handleSettingChangeSave(p, {target: {checked: false}})}
                                            />
                                        :   <CheckBoxOutlineBlankIcon style={{ color:"red" }} 
                                                onClick = {() => this.handleSettingChangeSave(p, {target: {checked: true}})}
                                            />
                                    :   <CheckBoxOutlineBlankIcon style={{ color:"grey" }} 
                                            onClick = {() => this.handleSettingChangeSave(p, {target: {checked: true}})}
                                        />
                                }
                                </span>
                                <span>&nbsp;{labels!==undefined? labels[index]: p}</span>
                                {this.props.infoText?.[index]?.length > 2
                                    &&  <span style={theme.textSupport}><br/>{this.props.infoText[index]}</span>
                                }
                            </p>
                        ))
                    }
                </Card>
                <Dialog
                    open={this.state.openSetting}
                    onClose={this.handleSettingClose}
                    fullWidth
                    maxWidth="sm"
                >
                    <DialogTitle>Change Setting</DialogTitle>
                    <form>
                    <DialogContent>
                        {
                            params.map((p, index) => (
                                
                                <div style={{width: `100%`}} key={"change_"+p}> 
                                    {labels!==undefined
                                        ? (labels[index] + ' => ')
                                        : (p + ' => ')
                                    } 
                                    <Switch
                                        key={"change_"+p}
                                        checked={this.state[p]}
                                        onChange={(e) => this.handleSettingChange(p, e)}
                                        value={"change_"+p}
                                        color="secondary"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                                </div>
                            ))
                        }
                        {this.state.errorMessage !== '' && this.state.errorMessage !== undefined
                            ?
                                <p style={theme.textErrorCenterPadding}>
                                    {this.state.errorMessage}
                                </p>
                            :   <p> </p>
                        }
                    </DialogContent>
                    </form>
                    <DialogActions>
                        <Button onClick={this.handleSettingClose}>
                            Cancel
                        </Button>
                        <Button onClick={() => this.handleSettingSubmit(undefined)}>
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                </div>
            : <div> </div>
                    
        );
    }
}

SettingContentActive.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(
    withStyles(styles, { withTheme: true }),
    withFirebase,
)(SettingContentActive);
