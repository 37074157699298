import React, { Component } from 'react';
import { compose } from 'recompose';
import { AuthUserContext } from '../Session';
import withStyles from '@material-ui/core/styles/withStyles';
import { withFirebase } from '../Firebase';
import { FindTextValueInDict, ReturnFilteredDict, QualityID } from '../Modules/LoadCategoriesAndQualities';
import { UserStageRequirements } from '../Admin/QuestionUtterances';
import {RenderMediaYoutube, RenderMediaVideo, RenderMediaAudio} from  '../Content/RenderMedia';
import { Link } from "react-router-dom";
import Masonry from 'react-masonry-css';
import BorderColorTwoToneIcon from '@material-ui/icons/BorderColorTwoTone';

// General Modules
import LoadingProgress from '../Modules/LoadingProgress';
import { IsMobileDevice } from '../Modules/IsMobileDevice';
import {AvatarCard} from '../Modules/AvatarImage';

import SwipeStepper from '../Modules/SwipeStepper';
import DialogKeyWords from '../Admin/Content/DialogKeyWords';
import {ConvertTimestampToDateString} from '../Modules/ConvertTimestampToDateString';
import TextField from '@material-ui/core/TextField';

import Popper from '@material-ui/core/Popper';
import CircularProgress from '@material-ui/core/CircularProgress';

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionActions';
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// Swipe
import VizSensor from 'react-visibility-sensor';
import SwipeableViews from 'react-swipeable-views';

import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Skeleton from '@material-ui/lab/Skeleton';
//popup
import {RemoveScroll} from 'react-remove-scroll';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
//import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
// Icons
import Button from '@material-ui/core/Button';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
//import AppsIcon from '@material-ui/icons/Apps';
import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import Filter9PlusIcon from '@material-ui/icons/Filter9Plus';
import PublicIcon from '@material-ui/icons/Public';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import SettingContentBase from '../Admin/Modules/SettingContentBase';
import SettingContentActive from '../Admin/Modules/SettingContentActive';
import SettingContentArrayCheckbox from '../Admin/Modules/SettingContentArrayCheckbox';
//import SettingContentNumber from '../Admin/Modules/SettingContentNumber';
import SettingContentArray from '../Admin/Modules/SettingContentArray';
//import SettingContentImage from '../Admin/Modules/SettingContentImage';
import SettingContentUploadImage from '../Admin/Modules/SettingContentUploadImage';
import SettingContentRichText from '../Admin/Modules/SettingContentRichText';
//import SettingContentArraySelect from '../Admin/Modules/SettingContentArraySelect';
import SettingContentSelect from '../Admin/Modules/SettingContentSelect';
import SettingContentMapSelect from '../Admin/Modules/SettingContentMapSelect';
import SettingContentMapList from '../Admin/Modules/SettingContentMapList';
import IntentArrayManagement from '../Admin/Modules/IntentArrayManagement';

//import BusinessIcon from '@material-ui/icons/Business';
//import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
//import WifiTetheringIcon from '@material-ui/icons/WifiTethering';
//import SchoolIcon from '@material-ui/icons/School';
import Tooltip from '@material-ui/core/Tooltip';
//import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import YouTubeIcon from '@material-ui/icons/YouTube';

import Slide from '@material-ui/core/Slide';
import { AccessCheckCoachCreator } from '../../constants/roles';
//import { NavigateBeforeSharp } from '@material-ui/icons';
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});


const styles = theme => ({
    grow: {
        flexGrow: 1, 
    },
    masonry:{
        width: "100%",
        display: "-webkit-box",
        display: '-ms-flexbox',
        display: "flex",
        paddingLeft: 0
    },
    masonryColumn :{
        paddingRight: 10,
        backgroundClip: "padding-box",
    },
    innerHTML : theme.innerHTML
})

class ManageContent extends Component {
    static contextType = AuthUserContext;
    _isMounted = false;
    constructor(props) {
        super(props);
        //console.log('probs in JournalWeek ',this.probs);
        const anchorRef = React.createRef();
        this.state = {
            loading: true,
            loadingText: 'loading programs',
            contents: null,
            searchTag: '',
            activeKey: (props.launchCategory || undefined)
        };
    }

    componentDidMount() {
        this._isMounted = true;
        this.loadContent();
    }

    componentDidUpdate(prevProps){
        if(this._isMounted ){
            //console.log('update selected Program', this.props, prevProps, this.state)
            if(this.props.launchCategory?.length > 2 && "showKey_"+this.props.launchCategory !== this.state.activeKey){
                //console.log('update new Program', this.props.launchCategory, this.state)
                this.setState({activeKey: "showKey_"+this.props.launchCategory});
            }
            if(this.props.launchQuality?.length > 2 && (this.state.activeKey + this.props.launchQuality) !== this.state.activeQualityKey){
                //console.log('update new qual', this.props.launchQuality, this.state)
                this.setState({activeQualityKey: (this.state.activeKey + this.props.launchQuality)});
            }
        }
    }

    setProgramModule(category = undefined, qualityvalue = undefined, tab = undefined){
        if(this._isMounted){
            if(this.props.clickAction!== undefined){
                this.props.clickAction(category, qualityvalue, tab)
            }
        }
    }

    loadContent(){
        if(this.unsubscribeByCat !== undefined){
            this.unsubscribeByCat();
        }
        if(this.unsubscribeByIntent !== undefined){
            this.unsubscribeByIntent();
        }
        if(this.unsubscribeByMother !== undefined){
            this.unsubscribeByMother();
        }
        var ownedGroups = [];
        let ownedGroupsData = {};
        //let contents = {};
        //let contentsByQuality = {};
        //let contentsData = {};
        let contentsByQuality = this.state.contentsByQuality || {};
        let contents = this.state.contents || {};
        let contentsData = this.state.contentsData || {};
        let contentsInitList = {};
        this.setState({ loading: true });
        let relatedCategories = [];
        let relatedCategoriesText = [];
        let relatedDefaultCategories = [];
        let relatedDefaultCategoriesText = [];
        var adminGroups = [];
        /// load by programs
        if(this.context.adminPrograms?.length> 0 && this.props.searchByIntent === undefined){
            adminGroups = this.context.adminPrograms;
            if(adminGroups.length > 10) {adminGroups = adminGroups.slice(0,9);}
            //console.log('MC adminGroups',adminGroups )
            this.props.firebase
            .leadershipCategories()
            .where('program', 'in', adminGroups)
            .get().then((data) => {
            //.onSnapshot((data) => {
                data.forEach(result => {
                    var myGroupsDoc = result.data();
                    if(myGroupsDoc.program !== 'default' || (myGroupsDoc.program === 'default' && (this.context.adminPrograms.includes(myGroupsDoc.value) || this.context.adminPrograms.includes('default_all')) )){
                        if((myGroupsDoc.active === true || AccessCheckCoachCreator(this.context) ) && myGroupsDoc.value !== 'default' ){
                            if(myGroupsDoc.program !== 'default'){
                                relatedCategories.unshift(myGroupsDoc.value);
                                relatedCategoriesText.unshift(myGroupsDoc.text)
                            }
                            else{
                                relatedDefaultCategories.push(myGroupsDoc.value);
                                relatedDefaultCategoriesText.push(myGroupsDoc.text)
                            }
                            ownedGroups.push(myGroupsDoc.value);
                            ownedGroupsData[myGroupsDoc.value] = myGroupsDoc;
                            contentsInitList[myGroupsDoc.value] = {"Quotes":[]};
                        }
                    }
                })
                var searchCategories = [...relatedCategories, ...relatedDefaultCategories];
                if(searchCategories.length > 10) {
                    if(this.state.pageIndex === 2){
                        searchCategories = [...relatedCategories.slice(10,19), ...relatedDefaultCategories];
                    }
                    else{
                        searchCategories = relatedCategories.slice(0,10);
                        this.setState({pageIndex : 1})
                    }
                }
                //console.log('original content', searchCategories , searchCategories);
                this.setState({
                    searchCategories,
                    relatedCategories: relatedCategories.concat(relatedDefaultCategories),
                    relatedCategoriesText: relatedCategoriesText.concat(relatedDefaultCategoriesText),
                });
                //console.log('MC search content', searchCategories );
                if(searchCategories?.length > 0){
                    this.unsubscribeByCat = this.props.firebase
                    .contents()
                    .where('leadership_category', 'in', searchCategories)
                    //.orderBy('leadership_category', 'asc')
                    //.where('roles', 'array-contains', 'COACHEE')
                    //.where('code', '==', 'PMCOACHING')
                    .orderBy('title', 'desc')
                    .onSnapshot(snapshot => {
                        if(this._isMounted){
                            //contents = {};
                            //contents = Object.assign({}, contentsInitList);
                            Object.keys(contentsInitList).forEach(key => {
                                if(searchCategories.includes(key)){
                                    var newDict = {"Quotes":[]}
                                    try{
                                        var programQualities = ReturnFilteredDict(this.props.firebase, this.props.leadershipQualities, key, 'category');
                                        programQualities.forEach(result => {
                                            newDict[QualityID(result.value)] = [];
                                        })
                                        //console.log('programQualities', programQualities)
                                    }
                                    catch(e){}
                                    contents[key] = newDict;
                                }
                            })
                            //console.log('recopy',contents, contentsInitList );
                            snapshot.forEach(doc => {
                                var contentData = doc.data();
                                if(contentData.title !== undefined){
                                    contentsData[doc.id] = { ...contentData, qid: doc.id };
                                }
                                //console.log('content', contents)
                                var groupId = contentData.leadership_category;
                                if(! Object.keys(contents).includes(groupId)){
                                    contents[groupId] = {"Quotes":[]};
                                }
                                var type = "none";
                                if(contentData.category!== undefined){
                                    type = contentData.category;
                                }
                                if (type === "tips"){
                                    if(contentData.leadership_quality !== undefined){
                                        contentData.leadership_quality.forEach( (qualityItem, qIndex) => {
                                            var quality = QualityID(qualityItem);
                                            //console.log('groupId', groupId, quality)
                                            var groupTempId = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, quality, 'category');
                                            if(groupTempId?.length > 2){
                                                groupId = groupTempId;
                                                if(! Object.keys(contents).includes(groupId)){
                                                    contents[groupId] = {"Quotes":[]};
                                                }
                                            }
                                            if(!(contents[groupId]?.[quality]?.includes(doc.id))){
                                                //// loadeed in page 1 or 2 and then missing in the subject
                                                //console.log('quality',qIndex, contentData.leadership_quality, groupId, quality, contentData.leadership_category)
                                                if(Object.keys(contents[groupId]).includes(quality)){
                                                    //contents[groupId][quality].push({ ...contentData, qid: doc.id });
                                                    if(contentData.leadership_category === groupId)
                                                        contents[groupId][quality].unshift(doc.id);
                                                    else
                                                        contents[groupId][quality].push(doc.id);
                                                }
                                                else{
                                                    //contents[groupId][quality] = [{ ...contentData, qid: doc.id }];
                                                    contents[groupId][quality] = [doc.id];
                                                }
                                                if(Object.keys(contentsByQuality).includes(quality)){
                                                    //contents[groupId][quality].push({ ...contentData, qid: doc.id });
                                                    if(! contentsByQuality[quality].includes(doc.id) )
                                                    contentsByQuality[quality].push(doc.id );
                                                }
                                                else{
                                                    //contents[groupId][quality] = [{ ...contentData, qid: doc.id }];
                                                    contentsByQuality[quality] = [doc.id];
                                                }
                                            }
                                        })
                                    }
                                    else{
                                        if(Object.keys(contents[groupId]).includes('undefined')){
                                            //contents[groupId]['undefined'].push({ ...contentData, qid: doc.id });
                                            contents[groupId]['undefined'].push(doc.id);
                                        }
                                        else{
                                            //contents[groupId]['undefined'] = [{ ...contentData, qid: doc.id }];
                                            contents[groupId]['undefined'] = [ doc.id ];
                                        }
                                    }
                                }
                                else if (type === "quotes"){
                                    if(Object.keys(contents[groupId]).includes('Quotes')){
                                        //contents[groupId]["Quotes"].push({ ...contentData, qid: doc.id });
                                        contents[groupId]["Quotes"].push( doc.id );
                                    }
                                    else{
                                        //contents[groupId]['Quotes'] = [{ ...contentData, qid: doc.id }];
                                        contents[groupId]['Quotes'] = [ doc.id ];
                                    }
                                }
                            });
                        }
                        //console.log('Contents loaded by category',contents, ownedGroupsData, relatedCategories)
                        if (this._isMounted) {
                            this.setState({
                                contents,
                                contentsByQuality,
                                ownedGroupsData,
                                contentsData,
                                loading: false,
                            });
                        }
                    });
                }
                else{
                    this.setState({
                        loading: false,
                        error: "Before anything else, you must either create 'Categories & Quests' or obtain administrative rights for existing ones."
                    });
                }
            })
        }
        else if(this.props.searchByIntent === undefined){
            if (this._isMounted) {
                this.setState({
                    loading: false,
                    error: "No assigned categories available."
                });
            }
        }
        let qualities = {};
        var myOwnedQualities = [];
        var myOwnedQualitiesText = [];
        var myMotherQualities = [];
        var qualitiesMotherQuality = [];
        var leadershipQualitiesGroups = {}
        var leadershipQualitiesGroupsText ={}
        /// load by qualities
        if(this.context.adminPrograms!== undefined && this.context.adminPrograms.length> 0 && this.props.searchByIntent === undefined){
            adminGroups = this.context.adminPrograms;
            if(adminGroups.length > 10) {adminGroups = adminGroups.slice(0,9);}
            this.props.firebase
            .leadershipQualities()
            .where('program', 'in', adminGroups)
            .orderBy('category', 'asc')
            .get().then(snapshot => {
                if(this._isMounted){
                    qualities = {};
                    myOwnedQualities = [];
                    myOwnedQualitiesText = [];
                    snapshot.forEach(doc => {
                        var group = "Undefined";
                        // *** HN adding filtering to users only allowed to be seen by coach who has same program
                        // any kind of program would work, but default program is filtered
                        var qualityData = doc.data();
                        if(qualityData.program !== 'default' || (qualityData.program === 'default' && (this.context.adminPrograms.includes(qualityData.category) || this.context.adminPrograms.includes('default_all')) )){
                            var qualId = QualityID(qualityData.valueID || qualityData.value);
                            var qualText = (qualityData.active === false ? 'inactive ' : "") + "(" + groupName + ") " + qualityData.text;
                            var groupId = qualityData.category || "Undefined";
                            if(qualityData.motherQuality?.length > 2 && !(qualityData.program === 'default')){
                                qualitiesMotherQuality[qualId] = qualityData.motherQuality;
                                if(!myMotherQualities.includes(qualityData.motherQuality)){
                                    myMotherQualities.push(qualityData.motherQuality);
                                }
                            }
                            if (qualityData.category!== undefined && qualityData.category!== ""){
                                var groupName = groupId;
                                groupName = FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, groupId);
                                groupId = groupName;
                                qualText = (qualityData.active === false ? 'inactive ' : "") + "(" + groupName + ") " + qualityData.text;
                                if(Object.keys(qualities).includes(groupId)){
                                    qualities[groupId].push({ ...qualityData, qid: doc.id });
                                }
                                else{
                                    qualities[groupId] = [{ ...qualityData, qid: doc.id }];
                                }
                                myOwnedQualities.push(qualId);
                                myOwnedQualitiesText.push(qualText);
                                if(!Object.keys(leadershipQualitiesGroups).includes(groupId)){
                                    leadershipQualitiesGroups[groupId] = [];
                                    leadershipQualitiesGroupsText[groupId] = [];
                                }
                                leadershipQualitiesGroups[groupId].push(qualId);
                                leadershipQualitiesGroupsText[groupId].push(qualText);
                            }
                            else{
                                if(Object.keys(qualities).includes(group)){
                                    qualities[group].push({ ...qualityData, qid: doc.id });
                                }
                                else{
                                    qualities[group] = [{ ...qualityData, qid: doc.id }];
                                }
                                myOwnedQualities.push(qualId);
                                myOwnedQualitiesText.push(qualText);
                                if(!Object.keys(leadershipQualitiesGroups).includes(groupId)){
                                    leadershipQualitiesGroups[groupId] = [];
                                    leadershipQualitiesGroupsText[groupId] = [];
                                }
                                leadershipQualitiesGroups[groupId].push(qualId);
                                leadershipQualitiesGroupsText[groupId].push(qualText);
                            }
                        }
                    });
                }
                //console.log("qualities selected", myOwnedQualities, leadershipQualitiesGroups, myMotherQualities, qualitiesMotherQuality)
                if (this._isMounted) {
                    this.setState({
                        qualitiesValues: myOwnedQualities,
                        qualitiesText: myOwnedQualitiesText,
                        myMotherQualities,
                        qualitiesMotherQuality,
                        leadershipQualitiesGroups,
                        leadershipQualitiesGroupsText
                    });
                }
                /// add motherqualities
                if(myMotherQualities?.length > 0){
                    //console.log("qualities selected", myOwnedQualities, leadershipQualitiesGroups, myMotherQualities)
                    //console.log('intent', this.props.searchByIntent)
                    adminGroups = this.context.adminPrograms;
                    contentsByQuality = this.state.contentsByQuality || {};
                    contents = this.state.contents || {};
                    contentsData = this.state.contentsData || {};
                    if(adminGroups.length > 10) {adminGroups = adminGroups.slice(0,9);}
                    this.unsubscribeByIntent = this.props.firebase
                    .contents()
                    .where('active', '==', true)
                    .where('category', '==', "tips")
                    .where('leadership_quality', 'array-contains-any', myMotherQualities)
                    .orderBy('title', 'desc')
                    .onSnapshot(snapshot => {
                        if(this._isMounted){
                            var groupId = "motherQualities";
                            contents[groupId] = {};
                            //console.log('contentsByQuality', contents, contentsByQuality)
                            snapshot.forEach(doc => {
                                var contentData = doc.data();
                                //console.log('contentData', contentData.title)
                                if(contentData.title !== undefined){
                                    contentsData[doc.id] = { ...contentData, qid: doc.id };
                                }
                                if(contentData.leadership_quality !== undefined){
                                    ////// GET ALL THE EXTRA QUALITIES FROM THE MOTHER POOL AND PUT THEM INTO THE TEMPLATES IF THEY ARE MOTHER QUALITITIES
                                    contentData.leadership_quality.forEach( quality => {
                                        if(quality?.length > 2 && contentData.program === 'default' && myMotherQualities.includes(quality)){
                                            if(Object.keys(contents[groupId]).includes(quality)){
                                                if(! contents[groupId][quality].includes(doc.id) )
                                                contents[groupId][quality].unshift(doc.id );
                                            }
                                            else{
                                                contents[groupId][quality] = [doc.id];
                                            }
                                            if(Object.keys(contentsByQuality).includes(quality)){
                                                if(! contentsByQuality[quality].includes(doc.id) )
                                                contentsByQuality[quality].unshift(doc.id );
                                            }
                                            else{
                                                contentsByQuality[quality] = [doc.id];
                                            }
                                        }
                                    })
                                }
                            });
                            //console.log('contentsByQuality', contents, contentsByQuality)
                            if (this._isMounted) {
                                this.setState({
                                    contents,
                                    contentsByQuality,
                                    contentsData
                                });
                            }
                        }
                    });
                }
            });
        }
        else if(this.props.searchByIntent === undefined){
            if (this._isMounted){
                this.setState({
                    error: "No assigned categories available."
                });
            }
        }
        if(this.props.searchByIntent !== undefined && this.props.searchByIntent !==  ""){
            //console.log('intent', this.props.searchByIntent)
            adminGroups = this.context.adminPrograms;
            if(adminGroups.length > 10) {adminGroups = adminGroups.slice(0,9);}
            this.unsubscribeByIntent = this.props.firebase
            .contents()
            .where('program', 'in', adminGroups)
            .where('category', '==', "tips")
            .where('utteranceClass', 'array-contains', this.props.searchByIntent)
            .orderBy('title', 'desc')
            .onSnapshot(snapshot => {
                if(this._isMounted){
                    var proposedList = {proposed : { [this.props.searchByIntent] : []}};
                    //// read from state so that the propsoed list stays complete while editing
                    if(this.state.contents !== undefined && this.state.contents !== null && this.state.contents.proposed !== undefined){
                        proposedList = {proposed :  this.state.contents.proposed};
                    }
                    contents = { assigned : {[this.props.searchByIntent] : []}, ...proposedList };
                    //console.log('contrwnt', contents)
                    snapshot.forEach(doc => {
                        var contentData = doc.data();
                        //console.log('contentData', contentData)
                        if(contentData.title !== undefined){
                            contentsData[doc.id] = { ...contentData, qid: doc.id };
                        }
                        var groupId = "assigned";
                        if(contentData.utteranceClass !== undefined){
                            contentData.utteranceClass.forEach( contentIntent => {
                                //console.log('subs', contentIntent)
                                if(contentIntent !== ""){
                                    if(Object.keys(contents[groupId]).includes(contentIntent)){
                                        contents[groupId][contentIntent].push(doc.id );
                                    }
                                    else{
                                        contents[groupId][contentIntent] = [doc.id];
                                    }
                                }
                            })
                        }
                        var intentsList=[];
                        Object.keys( contents["assigned"] ).forEach( item => {
                            if(item !== this.props.searchByIntent){
                                if( contents["assigned"][item].length > 2){
                                    intentsList.unshift(item);
                                }
                                else intentsList.push(item);
                            }
                        })
                        if(intentsList.length > 10) {
                            intentsList = intentsList.slice(0,9);
                        }
                        if(intentsList.length > 0){
                            this.props.firebase
                            .contents()
                            //.where('program', 'in', this.context.adminPrograms)
                            //.where('utteranceClass', 'not-in', [this.props.searchByIntent])
                            .where('category', '==', "tips")
                            .where('utteranceClass', 'array-contains-any', (intentsList || []))
                            .orderBy('title', 'desc')
                            .get().then(snapshot => {
                                if(this._isMounted){
                                    snapshot.forEach(doc => {
                                        if(!(Object.keys(contentsData).includes(doc.id))){
                                            var contentData = doc.data();
                                            //console.log('content missing', contentData)
                                            if(contentData.title !== undefined){
                                                contentsData[doc.id] = { ...contentData, qid: doc.id };
                                            }
                                            var groupId = "proposed";
                                            contents[groupId][this.props.searchByIntent].push(doc.id );
                                        }
                                    })
                                }
                            })
                        }
                    });
                }
                //console.log('fs',contents,ownedGroupsData,  relatedCategories)
                if (this._isMounted) {
                    this.setState({
                        contents,
                        ownedGroupsData,
                        contentsData,
                        loading: false,
                    });
                    if(Object.keys(contentsData).length < 1){
                        this.setState({
                            error: "No content found."
                        });
                    }
                }
            });
        }
    }

    componentWillUnmount() {
        this.setState({ loading: false, contents: null });
        this._isMounted = false;
        if(this.unsubscribeByCat!==undefined){this.unsubscribeByCat();}
        if(this.unsubscribeByIntent!==undefined){this.unsubscribeByIntent();}
        if(this.unsubscribeByMother!==undefined){this.unsubscribeByMother();}
        if(this.unsubscribeCat!==undefined){this.unsubscribeCat();}
        if(this.unsubscribeKeyWords !== undefined){
            this.unsubscribeKeyWords();
        }
    }

    render(){
        const {contents} = this.state;
        const {theme} = this.props;
        //console.log('manage conengt', this.state)
        return(
            <div>
                {this.state.pageIndex >=1 &&
                    <div style={{width: '100%', textAlign:'left', marginBottom: 40}}>
                        <span 
                            style={{color: theme.palette.secondary.main}}
                            onClick={() => {
                                this.setState({pageIndex: 1, activeKey: "", activeQualityKey: ""}, this.loadContent())
                                this.setProgramModule()
                            }}
                        >
                            Page 1
                        </span>
                        &nbsp; | &nbsp;
                        <span 
                            style={{color: theme.palette.secondary.main}}
                            onClick={() => {
                                this.setState({pageIndex: 2, activeKey: "", activeQualityKey: ""}, this.loadContent())
                                this.setProgramModule()
                            }}
                        >
                            Page 2
                        </span>
                    </div>
                }
                {this.state.selectedDefaultItem !== true
                &&
                    <div style={{marginBottom: 40}}>
                        <span 
                            onClick={() => {
                                this.setState({activeKey: "", activeQualityKey: ""})
                                this.setProgramModule()
                            }}
                            style={{
                                textDecorationLine : this.state.activeKey?.length > 1 ? "underline" : "none",
                                color : this.state.activeKey?.length > 1 ? theme.palette.secondary.main : undefined
                            }}
                        >
                            My Categories
                        </span>
                        {this.state.activeKey?.length>0
                            &&
                            <>
                                {" > "}
                                <span 
                                    onClick={() => {
                                        this.setState({activeQualityKey: ""})
                                        this.setProgramModule(this.state.activeKey?.replace("showKey_", ""))
                                    }}
                                    style={{
                                        textDecorationLine : this.state.activeQualityKey?.length>0 ? "underline" : undefined,
                                        color : this.state.activeQualityKey?.length>0 ? theme.palette.secondary.main : undefined
                                    }}
                                >
                                    {(FindTextValueInDict(this.props.firebase, this.props.leadershipCategories, this.state.activeKey?.replace('showKey_', "")) || this.state.activeKey) }
                                </span>
                            </>
                        }
                        {this.state.activeKey?.length>0 && this.state.activeQualityKey?.length>0
                            &&
                            <>
                                {" > "}
                                <span 
                                    onClick={() => {
                                        this.setState({activeQualityKey: ""})
                                        this.setProgramModule(this.state.activeKey?.replace("showKey_", ""))
                                    }}
                                    style={{
                                        textDecorationLine : this.state.activeQualityKey === 'showKey_'+this.state.activeQualityKey? "underline" : undefined,
                                        color : this.state.activeQualityKey === 'showKey_'+this.state.activeQualityKey ? theme.palette.secondary.main : undefined
                                    }}
                                >
                                    {(FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, this.state.activeQualityKey?.replace(this.state.activeKey, "")) || this.state.activeQualityKey) }
                                </span>
                            </>
                        }
                    </div>
                }
                {this.state.activeKey?.length>0
                &&
                    <div style={{width: '100%', textAlign:'left', marginBottom: 20}}>
                        <Link 
                            to={'#'}
                            style={{
                                textDecorationLine : "underline" ,
                                color : theme.palette.secondary.main 
                            }}
                            onClick={() => {
                                this.setState({activeKey: "", activeQualityKey: ""})
                                this.setProgramModule()
                            }}
                        >   
                            Show all Categories
                        </Link>
                    </div>
                }
                {this.state.loading 
                    ?
                        <LoadingProgress 
                            defaultSet={this.props.defaultSet}
                            type={'LoadingPageAnimated'}
                            loadingText={this.state.loadingText} 
                        />
                    :
                        contents!==undefined && contents!==null 
                        ?
                            <div>
                                {Object.keys(contents).map((key, index) => (
                                    key.length > 0 && key !== 'motherQualities'
                                    ?
                                        this.renderGroups( key, index, this.context.adminPrograms)
                                    :
                                        ""
                                ))}
                                {!(this.state.activeKey?.length > 2)
                                &&
                                    <div style={{width: '100%', marginBottom: 20, textAlign: 'left'}}>
                                        <Link 
                                            to={'#'}
                                            style={{
                                                textDecorationLine : "underline" ,
                                                color : theme.palette.secondary.main 
                                            }}
                                            onClick={() => {
                                                this.setProgramModule(undefined, undefined, "Categories & Quests")
                                            }}
                                        >   
                                            Edit Categories & Quests
                                        </Link>
                                    </div>
                                }
                            </div>
                        :
                            <div style={{paddingBottom: 20}}>
                                <p>No contents assigned yet!</p>
                                <span style={theme.textSupport}>{this.state.error}</span>
                            </div>
                }
                {
                    this.dialogueContentSettings()
                }
                <TextField
                    name="text"
                    type="text"
                    //label="Search by Text"
                    placeholder="Filter Selection by Text"
                    value={this.state.searchTag || ''}
                    onChange={ (event) => {this.setState({searchTag : event.target.value})}}
                    //fullWidth
                    style={ {
                        fontSize: '16px', 
                        backgroundColor:'white',
                        position: 'absolute',
                        top: 100,
                        right: 50,
                        width: 250,
                        paddingLeft: 15,
                        fontSize: 16,
                        borderRadius: 30,
                        background: 'linear-gradient(21deg, #ff3b3b, #ffffff)',
                        display: 'inline-block'
                    } }
                />
            </div>
        )
    }

    renderQualityAvatars (key) {
        const {contents , contentsByQuality} = this.state;
        const {theme} = this.props;
        return(
            <div>
                {Object.keys(contents[key])?.map( (item, index) => (
                    <span style={{marginRight: 20}}
                        key={index}
                        onClick={() => {
                            this.setState({activeQualityKey:'showKey_'+key+item})
                            this.setProgramModule(key, item)
                        }}
                    >
                        <AvatarCard key={index} dict={this.props.leadershipQualities} type='quality' item={item} squareSize='20' />
                    </span>
                ))}
            </div>
        )
    }
    renderQualityList (key) {
        const {contents} = this.state;
        const {theme} = this.props;
        return(
            <div>
                {Object.keys(contents[key])?.map( (item, index) => (
                    <span 
                        style={{
                            marginRight: 20,
                            textDecorationLine : this.state.activeQualityKey !== 'showKey_'+key+item ? "underline" : "none",
                            color :  this.state.activeQualityKey !== 'showKey_'+key+item ? theme.palette.secondary.main : undefined
                                
                        }}
                        key={index}
                        onClick={() => {
                            this.setState({activeQualityKey:'showKey_'+key+item})
                            this.setProgramModule(key, item)
                        }}
                    >
                        {FindTextValueInDict(undefined, this.props.leadershipQualities, item, 'text')}
                    </span>
                ))}
            </div>
        )
    }

    renderGroups = (key, index, childPrograms = undefined) => {
        const {contents} = this.state;
        const {theme} = this.props;
        return(
            <VizSensor 
                partialVisibility={true}
                key={key+'_viz_'+index}
                onChange={(isVisible) => { 
                    if(this._isMounted && isVisible && this.state['isVisible_'+key]===undefined) { 
                        this.setState({ ['isVisible_'+key] : true})
                        //console.log('t.leadership_category', t.leadership_category)
                    }}
                }
            >
            {(!(this.state.activeKey?.length > 2) || this.state.activeKey === 'showKey_'+key)
                ?
                    <Grid container spacing={2} direction='row' justify="space-between"
                        key={key+'_gr_'+index}
                        style={{height: 'calc(100% - 10px)', minHeight: 200, borderStyle: 'dotted', borderWidth: 1, borderCollapse: 'black', borderRadius: theme.borderRadius, padding: 10, marginBottom: 20}}
                    >
                        <Grid item xs={this.state.activeKey === 'showKey_'+key ? 8 : 12}
                            onClick={() => {
                                this.setState({activeQualityKey: ""})
                                this.setProgramModule(this.state.activeKey?.replace("showKey_", ""))
                            }}
                        >
                            {(!(this.state.activeKey?.length > 2) || this.state.activeKey === 'showKey_'+key )
                            ?   this.state.ownedGroupsData!== undefined && this.state.ownedGroupsData[key] !== undefined && this.state.ownedGroupsData[key].icon !== undefined
                                ?
                                    <AvatarCard key={key} type='imageIcon' 
                                        image={this.state.ownedGroupsData[key].icon} 
                                        textBlock={this.state.ownedGroupsData[key].text} 
                                        squareSize='20'  
                                        backgroundColor={this.state.ownedGroupsData[key].backgroundColor} />
                                :
                                    <AvatarCard 
                                        key={key} 
                                        dict={this.props.leadershipCategories} type='category'
                                        item={key} 
                                        squareSize='20' />
                            :   ""
                            }
                        </Grid>
                        {this.state.activeKey === 'showKey_'+key 
                        &&
                            <Grid item xs={4} style={{textAlign: 'right'}}>
                            </Grid>
                        }
                        {(this.state.activeKey !== 'showKey_'+key || (this.state.activeQualityKey?.length > 2) )
                        ?
                            <Grid item xs={12}>
                                {this.renderQualityList (key)}
                            </Grid>
                        :
                            <Grid item xs={12}>
                                {false && this.renderQualityAvatars (key)}
                            </Grid>
                        }
                        {this.state.activeKey === 'showKey_'+key && this.state.contentsData !== undefined
                            ?
                                <>
                                    {this.state['isVisible_'+key] && contents !== undefined && contents[key] !== undefined
                                        &&
                                            Object.keys(contents[key]).map((group, sindex) => (
                                                <Grid item xs={this.state.activeQualityKey === 'showKey_'+key+group ? 12 : 4} key={key+"_cont_"+sindex}>
                                                    {(!(this.state.activeQualityKey?.length > 2) || this.state.activeQualityKey === 'showKey_'+key+group)
                                                        ?
                                                            <>
                                                                <div style={{borderStyle: 'dotted', borderWidth: 1, borderCollapse: 'black', borderRadius: theme.borderRadius, padding: 20}}>
                                                                    Articles/Content (<strong>{contents?.[key]?.[group]?.length}</strong>)
                                                                    {this.renderViews(contents[key][group], key, group, true)}
                                                                </div>
                                                                {this.state.activeQualityKey?.length > 2 && this.state.qualitiesMotherQuality?.[group]?.length > 2 
                                                                &&
                                                                    <div style={{borderStyle: 'dotted', borderWidth: 1, borderCollapse: 'black', borderRadius: theme.borderRadius, padding: 20, marginTop: 20}}>
                                                                        Articles/Content from Template (<strong>{contents?.['motherQualities']?.[this.state.qualitiesMotherQuality[group]]?.length}</strong>)
                                                                        <br/>
                                                                        <span style={theme.textSupport}>
                                                                            All templates can be removed in the "Preloaded Template" in the "Sorting and Access Settings" of the Quest.
                                                                        </span>
                                                                        {this.renderViews(contents?.['motherQualities']?.[this.state.qualitiesMotherQuality[group]], 'motherQualities', this.state.qualitiesMotherQuality[group], true, childPrograms)}
                                                                    </div>
                                                                }
                                                            </>
                                                        :   ""
                                                    }
                                                </Grid>
                                            ))
                                    }
                                </>
                            :
                                !(this.state.activeKey?.length > 2)
                                ?
                                    <Grid item xs={12} 
                                        style={{marginBottom:20, textAlign: 'right'}}
                                        onClick={() => {
                                            this.setState({activeKey:'showKey_'+key})
                                            this.setProgramModule(key)
                                        }}
                                    >
                                        <Button
                                        >
                                            Add/Manage Content <ImageSearchIcon/>
                                        </Button>
                                    </Grid>
                                :   ""
                        }
                        {this.state.activeKey === 'showKey_'+key 
                        &&
                            <Grid item xs={12} style={{textAlign: 'left'}}>
                                <Link 
                                    to={'#'}
                                    style={{
                                        textDecorationLine : "underline" ,
                                        color : theme.palette.secondary.main 
                                    }}
                                    onClick={() => {
                                        this.setProgramModule(this.state.activeKey?.replace("showKey_", ""), undefined, "Categories & Quests")
                                    }}
                                >   
                                    Edit Category/Quests
                                </Link>
                            </Grid>
                        }
                    </Grid>
                :   <div>&nbsp;</div>
            }
            </VizSensor>
        )
    }

    renderViews = (contents, key, subKey, masonryType=true, childPrograms=undefined) => {
        const {theme, classes} = this.props;
        //console.log("swipe", key, contents)
        if (contents !== undefined){
            return(
                <div>
                    <div>
                        <Grid container spacing={0} direction='row' alignItems="center" style={{width:"100%", marginTop: 10}}>
                            <Grid item xs={12}
                                /**
                                onClick={() => {
                                    this.setState({activeQualityKey:' showKey_'+key+subKey})
                                    this.setProgramModule(this.state.activeKey?.replace("showKey_", ""), subKey)
                                }}
                                */
                            > 
                                <AvatarCard key={subKey} dict={this.props.leadershipQualities} type='quality' item={subKey} squareSize='20' />
                                &nbsp;
                                {key === "Other programs" &&
                                    <AvatarCard key={key} dict={this.props.leadershipCategories} type='category' item={key} squareSize='20' />
                                    // <span style={{color:"red"}}>{subKey}</span> - <strong>{key}</strong> 
                                }
                            </Grid>
                            {!(childPrograms?.length > 0) && this.state.activeQualityKey !== 'showKey_'+key+subKey && this.state.contentsData !== undefined
                            &&
                                <Grid item xs={12} style={{textAlign: 'right', marginBottom: 20}}>
                                    <Button 
                                        onClick={() => {
                                            this.setState({activeQualityKey:'showKey_'+key+subKey})
                                            this.setProgramModule(this.state.activeKey?.replace("showKey_", ""), subKey)
                                        }}
                                    >   
                                        {contents.length>0 ? "Show" : "Add"} content &nbsp; <ImageSearchIcon/>
                                        
                                    </Button>
                                </Grid>
                            }
                            {key !== 'motherQualities' && this.props.searchByIntent === undefined && key !== 'Other programs' && this.state.activeQualityKey === 'showKey_'+key+subKey
                                &&
                                <Grid item xs={12} style={{textAlign: 'right', marginBottom: 20}}>
                                    {subKey === 'Quotes'
                                        ?
                                            <Button onClick={ () => {this.addNewContent(key, 'quotes', 'Quotes')}} style={{float:"right", fontSize:'small', backgroundColor:'green'}}>
                                                Create&nbsp;Quote&nbsp;<PlaylistAddIcon/>
                                            </Button>
                                        :
                                            <Button onClick={ () => {this.addNewContent(key, 'tips', subKey)}} style={{float:"right", fontSize:'small', backgroundColor:'green'}}>
                                                Create&nbsp;Article&nbsp;<PlaylistAddIcon/>
                                            </Button>
                                    }
                                </Grid>
                            }
                        </Grid>
                    </div>
                    {(this.state.activeQualityKey === 'showKey_'+key+subKey || childPrograms?.length > 0) && this.state.contentsData !== undefined
                        ?   contents.length > 0
                            ?
                                <>
                                    {false && this.props.searchByIntent === undefined && subKey !== 'Quotes'
                                        &&
                                        <Button onClick={ () => {this.updateAIGroup(contents, key, subKey)}} 
                                            style={{float:"right", fontSize:'small', backgroundColor:'blue', marginBottom: 20}}
                                            disabled={this.state['loading_AI_'+key+subKey] ? true : false}>
                                            Update&nbsp;A.I.&nbsp;<AccountTreeIcon/>
                                        </Button>
                                    }
                                    {masonryType
                                    ?
                                        <Masonry
                                            breakpointCols={{default: 5}}
                                            className={classes.masonry}
                                            columnClassName={classes.masonryColumn}
                                        >
                                                {this.state.contentsData !== undefined &&  contents !== undefined && contents.map((item, index) => (
                                                    (this.state.contentsData[item].qid && (this.state.searchTag === undefined || this.state.searchTag === '' || 
                                                        this.state.contentsData[item].title.toLowerCase().includes(this.state.searchTag.toLowerCase() )))
                                                        ?
                                                            <VizSensor 
                                                                partialVisibility={true}
                                                                key={key+subKey+'_skel_'+index}
                                                                style={{width: 200, paddingRight: 10 }}
                                                                onChange={(isVisible) => { 
                                                                    if(isVisible && this.state['isVisibleItem_'+key+subKey+'_'+index]===undefined) { 
                                                                        this.setState({ ['isVisibleItem_'+key+subKey+'_'+index] : true})
                                                                        //console.log('t.leadership_category', t.leadership_category)
                                                                    }}
                                                                }
                                                            >
                                                            {
                                                                this.state['isVisibleItem_'+key+subKey+'_'+index]
                                                                ?
                                                                    this.renderContentCard(this.state.contentsData[item], this.state.contentsData[item].qid, key, subKey, childPrograms)
                                                                :
                                                                    <Skeleton variant="rect" width={200} height={200} />
                                                            }
                                                            
                                                            </VizSensor>
                                                        :
                                                            <div key={key+subKey+'_emp_'+index}
                                                                style={{marginLeft: -100, marginRight: -100, width:0}}> 
                                                            </div>
                                                    ))
                                                }
                                        </Masonry>
                                    :
                                        <>
                                            <SwipeStepper
                                                handlerSteps = {(step) => this.setState({['activeStep_'+key+subKey]:step}) }
                                                steps={(contents!== undefined && contents.length )|| 0}
                                                activeStep = {this.state['activeStep_'+key+subKey] || 0}
                                                showBackButton = {true}
                                            />
                                            <SwipeableViews
                                                key={key+subKey}
                                                axis={'x'}
                                                index={this.state['activeStep_'+key+subKey] || 0}
                                                onChangeIndex={(index) => {
                                                    try{
                                                        this.handleStepChange(index, key+subKey)
                                                    }
                                                    catch (err) {console.log('Swipe Error')}
                                                }}
                                                enableMouseEvents = {this.state.open === true? false : true}
                                                disabled = {this.state.open === true? true : false}
                                                style={{
                                                    paddingLeft: 0,
                                                    width: '30%',
                                                    maxWidth: 200,
                                                    overflow: 'visible',
                                                }}
                                                slideStyle={{
                                                    paddingRight: 0,
                                                    paddingBottom: 10,
                                                    flex: 'none',
                                                    maxWidth: 200,
                                                    width: 'auto'
                                                }}
                                            >
                                                {this.state.contentsData !== undefined &&  contents !== undefined && contents.map((item, index) => (
                                                    (this.state.contentsData[item].qid && (this.state.searchTag === undefined || this.state.searchTag === '' || 
                                                        this.state.contentsData[item].title.toLowerCase().includes(this.state.searchTag.toLowerCase() )))
                                                        ?
                                                            <VizSensor 
                                                                partialVisibility={true}
                                                                key={key+subKey+'_more_'+index}
                                                                style={{width: 200, paddingRight: 10 }}
                                                                onChange={(isVisible) => { 
                                                                    if(isVisible && this.state['isVisibleItem_'+key+subKey+'_'+index]===undefined) { 
                                                                        this.setState({ ['isVisibleItem_'+key+subKey+'_'+index] : true})
                                                                        //console.log('t.leadership_category', t.leadership_category)
                                                                    }}
                                                                }
                                                            >
                                                            {
                                                                this.state['isVisibleItem_'+key+subKey+'_'+index]
                                                                ?
                                                                    this.renderContentCard(this.state.contentsData[item], this.state.contentsData[item].qid, key, subKey, childPrograms)
                                                                :
                                                                    <Skeleton variant="rect" width={200} height={200} />
                                                            }
                                                            
                                                            </VizSensor>
                                                        :
                                                            <div key={key+subKey+'_'+index}
                                                                style={{marginLeft: -100, marginRight: -100, width:0}}> 
                                                            </div>
                                                    ))
                                                }
                                            </SwipeableViews>
                                        </>
                                    }
                                    
                                    {false && this.props.searchByIntent === undefined && this.context.roles?.includes("ADMIN")
                                        &&
                                        <Button onClick={ () => {this.resetAIGroup(contents, key, subKey)}} style={{ fontSize:'small', backgroundColor:'red'}}>
                                            Reset&nbsp;Actions+A.I.&nbsp;<AccountTreeIcon/>
                                        </Button>
                                    }
                                </>
                            :   ''
                        :
                            ""
                    }
                </div>
            )
        }
        else return "";
    }
    
    handleStepChange = (step, key) => {
        //console.log(step, key, 'handler')
        if(step !== undefined && key !== undefined) {
            this.setState({['activeStep_'+key] : step});
        }
    };

    addNewContent = (category, type, exclusiveTopic="") => {
        this.setState({activeQualityKey : 'showKey_'+category+exclusiveTopic });
        //console.log("initial ", category)
        var bgImage = "";
        if(this.state.ownedGroupsData!== undefined && this.state.ownedGroupsData[category]!== undefined){
            var qualityList = ['undefined'];
            var categoryData = this.state.ownedGroupsData[category];
            var initialKeyWord = categoryData.text;
            if(exclusiveTopic !== "" && exclusiveTopic !== "Quotes"){
                qualityList = [exclusiveTopic];
                bgImage = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, exclusiveTopic, 'url') ;
                initialKeyWord = FindTextValueInDict(this.props.firebase, this.props.leadershipQualities, exclusiveTopic, 'text') ;
            }
            else if( this.props.leadershipQualities !== undefined ){
                var relatedQualities = ReturnFilteredDict(this.props.firebase, this.props.leadershipQualities, category, 'category');
                if(relatedQualities!== undefined && relatedQualities.length > 0) {
                    qualityList = relatedQualities.map( x => x.value);
                }
                //console.log("related", qualityList)
            }
            //console.log("initial ", qualityList)
            if((qualityList?.length>=1 && qualityList?.[0] !== 'undefined')){
                var newContentData = {};
                var cleanedList = QualityID(qualityList);
                newContentData = {
                    active: false,
                    public: false,
                    createdAt: this.props.firebase.fieldValue.serverTimestamp(),
                    program: categoryData.program,
                    leadership_category: categoryData.value,
                    leadership_quality: cleanedList,
                    contentVotes: 1,
                    author: [this.context.email],
                    tags: [(initialKeyWord?.toLowerCase() || category.value)]
                }
                if( type === "tips"){
                    //// Add the Icon from the quality
                    if(categoryData.url !== undefined && bgImage?.includes("http")) {
                        newContentData['url'] = bgImage;
                    }
                    else if(categoryData?.url?.includes("http")) {
                        newContentData['url'] = categoryData.url;
                    }
                    if(categoryData.coach_name !== undefined && categoryData.coach_name!== "") {
                        newContentData['source'] = categoryData.coach_name;
                    }
                    if(categoryData.coach_contact_url !== undefined && categoryData.coach_contact_url!== "") {
                        newContentData['sourceUrl'] = categoryData.coach_contact_url;
                    }
                    newContentData['title'] = 'A NEW ARTICLE TITLE';
                    newContentData['subtitle'] = 'NEW SUBTITLE';
                    newContentData['category'] = 'tips';
                }
                else if( type === "quotes"){
                    newContentData['title'] = 'A NEW QUOTE.';
                    newContentData['subtitle'] = 'QUOTE AUTHOR';
                    newContentData['category'] = 'quotes';
                }
                else{
                    newContentData['title'] = 'A NEW CONTENT';
                    newContentData['subtitle'] = 'New Subtitle';
                    newContentData['category'] = 'undefined';
                }
                //console.log('add content', category, newContentData)
                this.props.firebase.contents().add(newContentData);
            }
        }
    }
    copyToNewContent = (content, category, type, exclusiveTopic="") => {
        this.setState({activeQualityKey : 'showKey_'+category+exclusiveTopic });
        console.log("initial ", category, type, exclusiveTopic)
        if(this.state.ownedGroupsData!== undefined && this.state.ownedGroupsData[category]!== undefined){
            var qualityList = ['undefined'];
            var categoryData = this.state.ownedGroupsData[category];
            if(exclusiveTopic !== "" && exclusiveTopic !== "Quotes"){
                qualityList = [exclusiveTopic];
            }
            else if( this.props.leadershipQualities !== undefined ){
                var relatedQualities = ReturnFilteredDict(this.props.firebase, this.props.leadershipQualities, category, 'category');
                if(relatedQualities!== undefined && relatedQualities.length > 0) {
                    qualityList = relatedQualities.map( x => x.value);
                }
                //console.log("related", qualityList)
            }
            //console.log("initial ", qualityList)
            if((qualityList?.length>=1 && qualityList?.[0] !== 'undefined') && this.context.adminPrograms?.includes(categoryData.program)){
                var newContentData = {};
                var cleanedList = QualityID(qualityList);
                newContentData = {
                    active: false,
                    public: false,
                    createdAt: this.props.firebase.fieldValue.serverTimestamp(),
                    program: categoryData.program,
                    leadership_category: categoryData.value,
                    leadership_quality: cleanedList,
                    contentVotes: 1,
                    author: [this.context.email],
                }
                newContentData = Object.assign({}, content, newContentData);
                if(newContentData?.updatedAt){
                    delete newContentData.updatedAt
                }
                if(newContentData?.qid){
                    delete newContentData.qid
                }
                if(newContentData?.analyzedDate){
                    delete newContentData.analyzedDate
                }
                if(newContentData?.isNonCommunity){
                    delete newContentData.isNonCommunity
                }
                if(newContentData?.isCourse){
                    delete newContentData.isCourse
                }
                if(newContentData?.unlockXP){
                    delete newContentData.unlockXP
                }
                //console.log('add content', category, newContentData)
                this.props.firebase.contents().add(newContentData);
                this.setState({visibility: false})
            }
        }
    }

    renderContentCard = (item, itemId, categoryKey, subKey, childPrograms = undefined) => {
        //console.log('imtem', item, itemId, categoryKey, subKey, this.state.contents )
        const {theme} = this.props;
        var secondColor = 'lightgrey';
        if(item.active){
            if(categoryKey === 'Other programs') secondColor = "orange";
            else secondColor = "green";
        }
        if(item?.programsHide?.some( ai => this.context.adminPrograms?.includes(ai) )){
            secondColor = "darkgrey";
        }
        var background = 'linear-gradient(to bottom, '+secondColor+' 0%, '+theme.palette.white+' 30%)';
        return(
            <Card key={itemId} style={{...theme.cardJournalDayHighlight, background: background, padding:0 }}   >
                {item.analyzedDate !== undefined &&
                    <div key={itemId} style={{ position:'absolute', width: '100%', paddingRight: 26, textAlign: 'right'}}>
                        <span style={{fontSize : 'small'}}>
                            {ConvertTimestampToDateString(item.analyzedDate, true) }
                        </span>
                    </div>
                }
                <CardContent>
                    {this.props.searchByIntent!== undefined && this.props.searchByIntent!== '' && categoryKey==="assigned"
                    && 
                        <Button style={{backgroundColor:"red"}} onClick={() => this.removeIntent(itemId, categoryKey, subKey)}>
                            Remove Intent (-)
                        </Button> 
                    }
                    {this.props.searchByIntent!== undefined && this.props.searchByIntent!== '' && categoryKey==="proposed"
                        && (item.utteranceClass === undefined || (item.utteranceClass !== undefined && !item.utteranceClass.includes(this.props.searchByIntent)))
                        && 
                        <Button style={{backgroundColor:"green"}} onClick={() => this.addIntent(itemId, categoryKey, subKey)}>
                            Add Intent (+)
                        </Button> 
                    }
                    <div>
                        {((item.s3Url !== undefined && item.s3Url !== '' ) || (item.youtubeId !== undefined && item.youtubeId !== '' ))
                            &&
                            <Tooltip title={'Multimedia included'}>
                                <YouTubeIcon fontSize="small" /> 
                            </Tooltip>
                        }      
                        {item.analyzed !== true && this.state['startedAITagging_'+itemId]
                            &&
                            <Tooltip title={'AI analyzed!'}>
                                <CircularProgress size={26} style={{color:"red"}} />
                            </Tooltip>
                        }
                        {item.validated
                            &&
                            <Tooltip title={'Text and actions validated!'}>
                                {item?.actions?.length < 5
                                    ?   <SpellcheckIcon fontSize="small" style={{color:"green"}} />
                                    :   <SpellcheckIcon fontSize="small" style={{color:"red"}} />
                                }
                            </Tooltip>
                        }
                        {item.analyzed
                            &&
                            <Tooltip title={'AI analyzed!'}>
                                <AccountTreeIcon fontSize="small" style={{color:"blue"}} />
                            </Tooltip>
                        }
                        {item.leadership_quality !== undefined && item.leadership_quality.length > 1 
                            && 
                            <Tooltip title={'Multiple modules included!'}>
                                <Filter9PlusIcon fontSize="small" style={{color:"orange"}} />  
                            </Tooltip>
                        }
                        {item.public
                            &&
                            <Tooltip title={'Public to the Rocky.ai Platform'}>
                                <PublicIcon fontSize="small" style={{color:"orange"}} />
                            </Tooltip>
                        }
                        {(item?.category === 'tips' && (item?.premium === true || (item?.program !== 'default' && !(item?.premium === false))) )
                            &&
                            <Tooltip title={'Premium content for subscription'}>
                                <ShoppingCartIcon fontSize="small" style={{color:"gold"}} />
                            </Tooltip>
                        }
                    </div>
                    <p>
                        <strong>{item.title}</strong>
                    </p>
                    {item.url!== ""
                        ?
                            <AvatarCard key={itemId} type='imageIcon' 
                                image={item.url} 
                                textBlock={"Upvotes: "+item.contentVotes} 
                                squareSize='20'  
                                backgroundColor={item.backgroundColor} />
                        :
                            <div>No Image</div>
                    }
                    <p> <span style={theme.textSupport}>{item.subtitle}</span></p>
                    <div>
                        {UserStageRequirements(this, item?.requirements || undefined)}
                    </div>
                    <hr/>
                    <div style={{width: "100%", textAlign: "center"}}>
                        {categoryKey === 'motherQualities'
                        ?
                           <Grid container spacing={0} direction='row' justify="space-between">
                                <Grid item xs={6}>
                                    <Button onClick={() => this.handleopenContentPreview(itemId, categoryKey, subKey)}>
                                        View
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    {!(childPrograms?.length > 0)
                                    ?   ""
                                    :   item?.programsHide?.some( ai => this.context.adminPrograms.includes(ai) )
                                        ?
                                            <Button onClick={() => this.unhideTemplate(itemId, categoryKey, childPrograms)}>
                                                Unhide
                                            </Button>
                                        :
                                            <Button onClick={() => this.hideTemplate(itemId, categoryKey, childPrograms)}>
                                                Hide
                                            </Button>
                                    }
                                </Grid>
                            </Grid>
                        :
                            <Button onClick={() => this.handleopenContentSettings(itemId, categoryKey, subKey)}>
                                Edit&nbsp;Content<BorderColorTwoToneIcon fontSize="small" style={{...theme.inlineIconRight, color: 'red'}}/>
                            </Button>
                        }
                    </div>
                </CardContent>
            </Card>
        )
    }
    
    handleClose = () => {
        this.setState({openContentSettings: false, openCategorySettings: false, openNotificationSettings: false, visibility: false, open: false} )
    }
    handleopenContentSettings = (qid, categoryKey, subKey) => {
        this.setState({selectedItemId: qid, selectedCategoryKey: categoryKey, keyWordsDict: {}, subKey, openContentSettings: true, visibility: false, openCategorySettings: false, openNotificationSettings: false, open:true} );
        if(this.unsubscribeKeyWords !== undefined){
            this.unsubscribeKeyWords();
        }
        this.unsubscribeKeyWords = this.props.firebase.collectionByName('contents/'+qid+'/keyWords')
        //.get().then((snapshot) => {
        .onSnapshot((snapshot) => {
            let keyWordsDict = {};
            snapshot.forEach(doc => {
                let newSubDict = doc.data();
                if(newSubDict?.[doc.id]){
                    keyWordsDict[doc.id] = newSubDict[doc.id];
                }
            })
            //console.log('loaded keyWordsDict', keyWordsDict)
            this.setState({keyWordsDict: keyWordsDict});
        })
    }
    handleopenContentPreview = (qid, categoryKey, subKey) => {
        this.setState({selectedItemId: qid, selectedCategoryKey: categoryKey, keyWordsDict: {}, subKey, openContentSettings: false, visibility: true, openCategorySettings: false, openNotificationSettings: false, open:true} );
        if(this.unsubscribeKeyWords !== undefined){
            this.unsubscribeKeyWords();
        }
    }

    hideTemplate = (itemId, categoryKey, childPrograms) => {
        //console.log('remove intent', itemId, categoryKey, subKey)
        if(childPrograms?.length > 0 && itemId !== "" && categoryKey === "motherQualities"){
            this.props.firebase.content(itemId).update({
                programsHide: this.props.firebase.fieldValue.arrayUnion(...childPrograms)
            })
        }
    }
    unhideTemplate = (itemId, categoryKey, childPrograms) => {
        if(childPrograms?.length > 0 && itemId !== "" && categoryKey === "motherQualities"){
            this.props.firebase.content(itemId).update({
                programsHide: this.props.firebase.fieldValue.arrayRemove(...childPrograms)
            })
        }
    }

    removeIntent = (itemId, categoryKey, subKey) => {
        console.log('remove intent', itemId, categoryKey, subKey)
        if(itemId !== "" && subKey !== "" && categoryKey === "assigned"){
            this.props.firebase.content(itemId).update({
                utteranceClass: this.props.firebase.fieldValue.arrayRemove(subKey)
            })
        }
    }
    addIntent = (itemId, categoryKey, subKey) => {
        console.log('add intent', itemId, categoryKey, subKey)
        if(itemId !== "" && subKey !== "" && categoryKey === "proposed"){
            this.props.firebase.content(itemId).update({
                utteranceClass: this.props.firebase.fieldValue.arrayUnion(subKey)
            })
        }
    }

    dialogueContentSettings = () => {
        const {theme, classes} = this.props;
        const {selectedItemId, selectedCategoryKey, subKey, keyWordsDict} = this.state;
        var content = undefined;
        var itemId = undefined;
        if(this.state.contentsData !==undefined && (this.state.openContentSettings || this.state.visibility) && selectedItemId !== undefined && selectedItemId !== "" && selectedCategoryKey!==undefined
            && this.state.contents !== undefined && subKey !== undefined 
                && this.state.contentsData[selectedItemId] !== undefined 
                // && (this.state.contents[selectedCategoryKey] !== undefined && this.state.contents[selectedCategoryKey][subKey]!== undefined)
            ){
            //content =  this.state.contents[selectedCategoryKey][subKey].find(q => q.qid === selectedItemId );
            content = this.state.contentsData[selectedItemId];
            itemId = selectedItemId;
        }
        //console.log('conetnt popup', content, selectedItemId)
        return(
            <RemoveScroll enabled={this.state.openContentSettings || (content?.type !== "quotes" && this.state.visibility ) || false}>
                <Dialog
                    key={this.state.actionId}
                    open={this.state.openContentSettings|| false}
                    onClose={this.handleClose}
                    fullScreen={IsMobileDevice()}
                    PaperProps={{style: {...theme.dialogWindow, position: "absolute", right: 10}}}
                    TransitionComponent={Transition}
                    maxWidth={"sm"}
                >
                    <DialogTitle style={theme.dialogTitle}  >
                        <Grid container spacing={0} direction='row' justify="space-between" >
                            <Grid item >
                                <IconButton onClick={this.handleClose}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Grid>
                            <Grid item >
                                <span style={theme.dialogTitle.titleText}>
                                    {itemId !== undefined && content !== undefined && content.analyzed !== true && this.state['startedAITagging_'+itemId]
                                        &&
                                        <CircularProgress size={26} style={{color:"red"}} /> 
                                    }
                                    &nbsp;Content Management
                                </span>
                            </Grid>
                            <Grid item >
                                {content !== undefined && content.category === "tips"
                                &&
                                    <Button size="small" onClick={() => { this.setState({visibility:true} ) }}>
                                        View Article Preview
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                    </DialogTitle>
                    <DialogContent style={theme.dialogContent}>
                        { itemId!==undefined && content !== undefined 
                            ? 
                                <div>
                                    {content.category === "quotes"
                                        ?
                                        <>
                                            <SettingContentBase 
                                                dbUpdateLink={this.props.firebase.content(itemId)}
                                                title={"Quote"} 
                                                max={100}
                                                content={content} 
                                                labels={['Quote (no quotation marks)', 'Author']}
                                                params={['title', 'subtitle']}
                                                updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                />
                                            {(content.active === true || content.subtitle !== "NEW SUBTITLE" || content.subtitle !== "")
                                            &&
                                                <SettingContentActive 
                                                    dbUpdateLink={this.props.firebase.content(itemId)}
                                                    title={"Active and public Content"} 
                                                    content={content} 
                                                    labels={['Active in the app']}
                                                    params={['active']}
                                                    updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                    />
                                            }
                                            {(this.context.roles?.includes("ADMIN") || content.public === true)
                                            &&
                                                <SettingContentActive 
                                                    dbUpdateLink={this.props.firebase.content(itemId)}
                                                    title={"Public"} 
                                                    content={content} 
                                                    labels={['Can be found by people outside the program']}
                                                    params={['public']}
                                                    updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                />
                                            }
                                            <SettingContentUploadImage
                                                dbUpdateLink={this.props.firebase.content(itemId)}
                                                title={"Quote Image"} 
                                                imgDef={"Square format, min 400px width, keep 30% padding on top and bottom, JPG or GIF, max. 250kb"}
                                                noLabel={true}
                                                imgFormat={"square"}
                                                imgMinWidth={400}
                                                previewImageSize={true}
                                                content={content} 
                                                params={['url']}
                                                uploadDatabase={this.props.firebase.storageImages('content/'+itemId)}
                                                updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                />
                                            <p>
                                                Actions are proposals that allow the enduser to define a follow-up related to the article.
                                                To show all actions, this article needs an activate flag for: "Grammar, summaries and actions checked & approved".
                                            </p>
                                            <SettingContentArray 
                                                dbUpdateLink={this.props.firebase.content(itemId)}
                                                title={'Define 2-4 actions related to the learning with: "I will..."'} 
                                                max={80}
                                                noLabel={true}
                                                labels={["Follow-up action proposals"]}
                                                content={content} 
                                                params={['actions']}
                                                updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                />
                                            <h4>Program: {content.program === "default" ? "Public Programs of Rocky.ai" : content.program}</h4>
                                            <Grid container spacing={0} direction='row' alignItems="bottom" style={{width:"100%"}}>
                                                <Grid item xs={6}>
                                                    <SettingContentSelect
                                                        dbUpdateLink={this.props.firebase.content(itemId)}
                                                        title={"Choose the main assigned category"} 
                                                        content={content} 
                                                        label={['Program Main Module']}
                                                        params={['leadership_category']}
                                                        selectOptions={this.state.relatedCategories}
                                                        selectOptionsText={this.state.relatedCategoriesText}
                                                        updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <SettingContentArrayCheckbox
                                                        dbUpdateLink={this.props.firebase.content(itemId)}
                                                        title={"Related to modules (multiple possible)"} 
                                                        content={content} 
                                                        params={['leadership_quality']}
                                                        noLabel={true}
                                                        selectPreOpen={true}
                                                        selectOptions={this.state.qualitiesValues}
                                                        selectOptionsText={this.state.qualitiesText}
                                                        selectOptionsGroups={this.state.leadershipQualitiesGroups}
                                                        selectOptionsGroupsText={this.state.leadershipQualitiesGroupsText}
                                                        updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                        />
                                                    {content.topicTitle !== undefined && content.topicTitle !== '' &&  
                                                        <p>Original Quest: <strong>{content.topicTitle}</strong></p>
                                                    }
                                                </Grid>
                                            </Grid>
                                            <SettingContentMapSelect
                                                dbUpdateLink={this.props.firebase.content(itemId)}
                                                title={"Optional: Promote article on a weekday"} 
                                                content={content} 
                                                params={['promotion']}
                                                noLabel={true}
                                                mapOptions={['weekday']}
                                                mapOptionsText={['Day of promotion']}
                                                selectOptionsText={['No special day', 'Monday', 'Tuesday','Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ]}
                                                selectOptions={['default', 'monday', 'tuesday','wednesday', 'thursday', 'friday', 'saturday', 'sunday']}
                                                updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                />
                                        </>
                                        :
                                        <>
                                            <SettingContentBase 
                                                dbUpdateLink={this.props.firebase.content(itemId)}
                                                title={"Title of Article (max. 40 characters, Capitalized Title)"}
                                                titleFormat={true}
                                                content={content}  
                                                showRealTimeText={content.active ? false : true}
                                                max={40}
                                                noLabel={true}
                                                params={['title']}
                                                updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                />
                                            <SettingContentBase 
                                                dbUpdateLink={this.props.firebase.content(itemId)}
                                                title={"Subtitle of Article (max. 80 characters, capitalized, no punctuation)"} 
                                                titleFormat={true}
                                                showRealTimeText={content.active ? false : true}
                                                content={content} 
                                                noLabel={true}
                                                max={80}
                                                params={['subtitle']}
                                                updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                />
                                            <SettingContentRichText
                                                dbUpdateLink={this.props.firebase.content(itemId)}
                                                title={"Article Rich Text Editor"}
                                                contentVariants={keyWordsDict?.texts || undefined}
                                                content={content} 
                                                noLabel={true}
                                                params={['text']}
                                                infoText={["Even if this article is only a video or infograph, you need to add some text describing the content."]}
                                                addWordCount={true}
                                                updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                updateVariants={(updateSet, variantIndex) => {
                                                    let texts = keyWordsDict?.texts || [];
                                                    //console.log('loadl updatedad texts', texts)
                                                    if(texts.length > 1 && variantIndex !== undefined){
                                                        texts[variantIndex] = updateSet.text;
                                                        console.log('mew updatedad texts', texts)
                                                        this.props.firebase.documentIdByCollection('contents/'+itemId+'/keyWords', 'texts').update({texts: texts})
                                                    }
                                                }}
                                            />
                                            {content?.textWordCount > 0 &&
                                                <p style={{textAlign: 'right'}}>
                                                    {content.textWordCount + " Words ("+ Math.round(content.textWordCount/100*0.6) + " minutes)" } 
                                                </p>
                                            }
                                            {(content.active === true || (content.subtitle !== "NEW SUBTITLE" && content.subtitle !== "" && content.text?.length > 20))
                                            &&
                                                <Grid container spacing={0} direction='row' alignItems="baseline" style={{width:"100%"}}>
                                                    {(content.analyzed!==true || this.state['startedAITagging_'+itemId])
                                                    &&
                                                        <>
                                                            <Grid item xs={12}>
                                                                <hr/>
                                                                <div style={{width: "100%", textAlign: "center"}}>
                                                                    <Button 
                                                                        style={{textAlign: 'center', backgroundColor : (content.analyzed || this.state['startedAITagging_'+itemId] )? "grey" : "red" }} 
                                                                        onClick={() => {
                                                                            if(content.active !== true){
                                                                                this.props.firebase.content(itemId).update({active: true})
                                                                                .then(() => this.startAITagging(itemId, true))
                                                                            }
                                                                            else{
                                                                                this.startAITagging(itemId, true)
                                                                            }
                                                                        }}
                                                                    >
                                                                        Start AI Content Curation
                                                                    </Button>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <div style={{...theme.textSupport, paddingLeft: 20}}>
                                                                    The AI button will update the tags and short explanations. Please remove unnecessary tags after AI sample creation! 
                                                                    {(keyWordsDict?.texts?.length > 1)
                                                                        && <span style={{color: theme.palette.themeAlertRed}}><br/>☐ 1/6 Added alternative Rich Text Variants</span>
                                                                    }
                                                                    {(keyWordsDict?.explanation?.length > 1)
                                                                        && <span style={{color: theme.palette.themeAlertRed}}><br/>☐ 2/6 Added explanation/conclusion summaries</span>
                                                                    }
                                                                    {(keyWordsDict?.tags?.length > 1)
                                                                        && <span style={{color: theme.palette.themeAlertRed}}><br/>☐ 3/6 Added search and key words</span>
                                                                    }
                                                                    {(keyWordsDict?.actions?.length > 1)
                                                                        && <span style={{color: theme.palette.themeAlertRed}}><br/>☐ 4/6 Added commitment examples</span>
                                                                    }
                                                                    {(keyWordsDict?.questions?.length > 1)
                                                                        && <span style={{color: theme.palette.themeAlertRed}}><br/>☐ 5/6 Added coaching question examples</span>
                                                                    }
                                                                    {(keyWordsDict?.utteranceDescriptions?.response !== undefined)
                                                                        && <span style={{color: theme.palette.themeAlertRed}}><br/>☐ 6/6 Finished adding AI labels</span>
                                                                    }
                                                                </div>
                                                            </Grid>
                                                        </>
                                                    }
                                                    <Grid item xs>
                                                        <SettingContentActive 
                                                            dbUpdateLink={this.props.firebase.content(itemId)}
                                                            title={"Active"} 
                                                            content={content} 
                                                            labels={['Active in the app']}
                                                            params={['active']}
                                                            updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                        />
                                                    </Grid>
                                                    <Grid item xs>
                                                        <SettingContentActive 
                                                            //dbUpdateLink={this.props.firebase.content(itemId)}
                                                            title={"Free Access"} 
                                                            content={{...content, premium: ((content?.premium === false) || (content?.program === "default" && content?.premium !== true))}} 
                                                            labels={['Access without subscription']}
                                                            params={['premium']}
                                                            updateState={(updateSet) => {
                                                                //console.log('updateset', updateSet)
                                                                this.props.firebase.content(itemId).update({premium: !(updateSet.premium) })
                                                            }}
                                                        />
                                                    </Grid>
                                                    {(this.context.roles?.includes("ADMIN") || content.public === true)
                                                    &&
                                                        <Grid item xs>
                                                            <SettingContentActive 
                                                                dbUpdateLink={this.props.firebase.content(itemId)}
                                                                title={"Public"} 
                                                                content={content} 
                                                                labels={['Can be found by people outside the program']}
                                                                params={['public']}
                                                                updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                            />
                                                        </Grid>
                                                    }
                                                </Grid>
                                            }
                                            {(content.subtitle !== "NEW SUBTITLE" && content.subtitle !== "" && content.text?.length > 20)
                                                &&
                                                <>
                                                    <h4>Program: {content.program}</h4>
                                                    <Grid container spacing={0} direction='row' alignItems="baseline" style={{width:"100%"}}>
                                                        <Grid item xs={6}>
                                                            <SettingContentUploadImage
                                                                dbUpdateLink={this.props.firebase.content(itemId)}
                                                                title={"Highlight-Image for Article"} 
                                                                imgDef={"16:9 format, min 600px width, JPG or GIF, max. 700kb"}
                                                                //imgExtensionList={[".png"]}
                                                                imgFormat={"rectangle"}
                                                                imgMinWidth={600}
                                                                previewImageSize={true}
                                                                content={content} 
                                                                noLabel={true}
                                                                params={['url']}
                                                                uploadDatabase={this.props.firebase.storageImages('content/'+itemId)}
                                                                updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                                />
                                                            <SettingContentUploadImage
                                                                dbUpdateLink={this.props.firebase.content(itemId)}
                                                                title={"Support-Image for Article"} 
                                                                imgDef={"Min 600px width, JPG or GIF, max. 700kb"}
                                                                //imgExtensionList={[".png"]}
                                                                //imgFormat={"rectangle"}
                                                                imgMinWidth={600}
                                                                previewImageSize={true}
                                                                content={content} 
                                                                noLabel={true}
                                                                params={['contentImage']}
                                                                uploadDatabase={this.props.firebase.storageImages('content/'+itemId)}
                                                                updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                                />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <SettingContentSelect 
                                                                dbUpdateLink={this.props.firebase.content(itemId)}
                                                                title={"Optional: Video/Audio (Link)"} 
                                                                labels={['Choose additional media type']}
                                                                content={content} 
                                                                params={['mediaType']}
                                                                selectOptions={['none', 'audio', 'video', 'youtube']}
                                                                selectOptionsText={['none', 'Audio Stream URL', 'Video Stream URL', 'Youtube ID Code']}
                                                            />
                                                            {content.mediaType!== undefined && (content.mediaType === 'audio' || content.mediaType === 'video') &&
                                                                <SettingContentBase 
                                                                    dbUpdateLink={this.props.firebase.content(itemId)}
                                                                    title={"External URL to mp3/mp4/stream file"} 
                                                                    content={content} 
                                                                    labels={['Link to media stream or file']}
                                                                    params={['s3Url']}
                                                                />
                                                            }
                                                            {(((content?.mediaType === 'youtube') || (content.mediaType === undefined && content.youtubeId !== undefined)) ) &&
                                                                <SettingContentBase 
                                                                    dbUpdateLink={this.props.firebase.content(itemId)}
                                                                    title={"Youtube Video Code (e.g. lzPJY1sTk4c)"} 
                                                                    content={content} 
                                                                    emptyOption={true}
                                                                    youTubeFormat={true}
                                                                    labels={['Only youtubeId code']}
                                                                    params={['youtubeId']}
                                                                />
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    <h4>Content Highlights</h4>
                                                    <div style={{...theme.textSupport, paddingLeft: 20}}>
                                                        Commitments are proposals that allow the enduser to define a follow-up related to the article.
                                                        To show all actions, this article needs an activate flag for: "Grammar, summaries and actions checked & approved".
                                                    </div>
                                                    {content.validated && this.context.roles?.includes("ADMIN")
                                                        ?
                                                            <IntentArrayManagement
                                                                dbUpdateLink={this.props.firebase.content(itemId)}
                                                                title={"3 Commitments (AI Training mode)"} 
                                                                content={content}
                                                                keepContent={true}
                                                                params={['actions']}
                                                                noLabel={true}
                                                                searchIntents={true}
                                                                exampleResults = {this.state['exampleResults_'+'actions'] || undefined }
                                                                updateExampleResults = { (exampleResults) => {this.setState({['exampleResults_'+'actions']: exampleResults}) }}
                                                                updateState = {(resultList) => {
                                                                    //console.log("updateState 3com", resultList)
                                                                    if(resultList?.length > 0){
                                                                        const uniqueElements = new Set([...resultList, ...(keyWordsDict?.actions || [])]);
                                                                        const newArray = Array.from(uniqueElements);
                                                                        this.props.firebase.documentIdByCollection('contents/'+itemId+'/keyWords', 'actions').update({actions: newArray})
                                                                    }
                                                                }}
                                                            />
                                                        :
                                                            (!(content.actions?.length > 2) && keyWordsDict?.actions?.length > 0)
                                                                ?
                                                                    <SettingContentArrayCheckbox
                                                                        dbUpdateLink={this.props.firebase.content(itemId)}
                                                                        title={"Select the 3 best matching commitments"} 
                                                                        content={content} 
                                                                        selectOptions={[...(keyWordsDict?.actions || [])]}
                                                                        noLabel={true}
                                                                        infoText={['After the selection you can also modify the text.']}
                                                                        params={['actions']}
                                                                        updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                                        updateState={ (updateState) => {
                                                                            if((updateState?.actions?.length >= 2 && content?.explanation?.length >= 2 && updateState?.actions?.length <= 6 && content?.explanation?.length <=6)){
                                                                                this.props.firebase.content(itemId).update({validated: true})
                                                                            }
                                                                        }}
                                                                    />
                                                                :
                                                                    <SettingContentArray 
                                                                        dbUpdateLink={this.props.firebase.content(itemId)}
                                                                        title={'Define 3 commitments related to the learning with: "I will..."'} 
                                                                        max={80}
                                                                        noLabel={true}
                                                                        labels={["Follow-up commitment proposals"]}
                                                                        content={content} 
                                                                        params={['actions']}
                                                                        updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                                        updateState = {(updateState) => {
                                                                            //console.log("updateState 3com", updateState)
                                                                            var resultList = updateState.actions || [];
                                                                            if(resultList?.length > 0){
                                                                                const uniqueElements = new Set([...resultList, ...(keyWordsDict?.actions || [])]);
                                                                                const newArray = Array.from(uniqueElements);
                                                                                this.props.firebase.documentIdByCollection('contents/'+itemId+'/keyWords', 'actions').update({actions: newArray})
                                                                            }
                                                                        }}
                                                                    />
                                                    }
                                                    <div style={{...theme.textSupport, paddingLeft: 20}}>
                                                        This is important! Explanations will be used by the chatbot with your words and phrases - Should be simple and clean.
                                                    </div>
                                                    {content.validated && this.context.roles?.includes("ADMIN")
                                                        ?
                                                            <IntentArrayManagement
                                                                dbUpdateLink={this.props.firebase.content(itemId)}
                                                                title={"Examples Explanations (AI Training mode)"} 
                                                                content={content}
                                                                keepContent={true}
                                                                params={['explanation']}
                                                                noLabel={true}
                                                                searchIntents={true}
                                                                exampleResults = {this.state['exampleResults_'+'explanation'] || undefined }
                                                                updateExampleResults = { (exampleResults) => {this.setState({['exampleResults_'+'explanation']: exampleResults}) }}
                                                                updateState = {(resultList) => {
                                                                    if(resultList?.length > 0){
                                                                        const uniqueElements = new Set([...resultList, ...(keyWordsDict?.explanation || [])]);
                                                                        const newArray = Array.from(uniqueElements);
                                                                        this.props.firebase.documentIdByCollection('contents/'+itemId+'/keyWords', 'explanation').update({explanation: newArray})
                                                                    }
                                                                }}
                                                            />
                                                        :
                                                            (!(content.explanation?.length > 2) && keyWordsDict?.explanation?.length > 0)
                                                            ?
                                                                <SettingContentArrayCheckbox
                                                                    dbUpdateLink={this.props.firebase.content(itemId)}
                                                                    title={"Select the 3 best matching conclusions"} 
                                                                    content={content} 
                                                                    selectOptions={[...(keyWordsDict?.explanation || [])]}
                                                                    noLabel={true}
                                                                    infoText={['After the selection you can also modify each text.']}
                                                                    params={['explanation']}
                                                                    updateState={ (updateState) => {
                                                                        if((content?.actions?.length >= 2 && updateState?.explanation?.length >= 2 && content?.actions?.length <= 6 && updateState?.explanation?.length <=6)){
                                                                            this.props.firebase.content(itemId).update({validated: true})
                                                                        }
                                                                    }}
                                                                    updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                                />
                                                            :
                                                                <SettingContentArray 
                                                                    dbUpdateLink={this.props.firebase.content(itemId)}
                                                                    title={"Use 3 short Conclusions of the article, used for conlusions, inspiration and goals."} 
                                                                    addTrainingData={true}
                                                                    content={content} 
                                                                    max={150}
                                                                    noLabel={true}
                                                                    params={['explanation']}
                                                                    labels={['Key conclusions']}
                                                                    updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                                    updateState = {(updateState) => {
                                                                        var resultList = updateState.explanation || [];
                                                                        if(resultList?.length > 0){
                                                                            const uniqueElements = new Set([...resultList, ...(keyWordsDict?.explanation || [])]);
                                                                            const newArray = Array.from(uniqueElements);
                                                                            this.props.firebase.documentIdByCollection('contents/'+itemId+'/keyWords', 'explanation').update({explanation: newArray})
                                                                        }
                                                                    }}
                                                                />
                                                    }
                                                    {(content.validated === true || (content?.actions?.length >= 2 && content?.explanation?.length >= 2 && content?.actions?.length <=6 && content?.explanation?.length <=6))
                                                        ?
                                                            <SettingContentActive 
                                                                dbUpdateLink={this.props.firebase.content(itemId)}
                                                                title={"Make commitment and explanations/summaries public"} 
                                                                content={content} 
                                                                labels={['Approve Commitments & Key Explanations']}
                                                                params={['validated']}
                                                                updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                                />
                                                        :   (content.validated !== true && content?.actions?.length >= 2 && content?.explanation?.length >= 2 )
                                                            ?
                                                                <p>
                                                                    <span style={{color:'red'}}>
                                                                    ! To make commitments and phrases/summaries public you need at least 2 to 5 for each of them !
                                                                    </span>
                                                                    <SettingContentActive 
                                                                        dbUpdateLink={this.props.firebase.content(itemId)}
                                                                        title={"Make actions and explanations/summaries public"} 
                                                                        content={content} 
                                                                        labels={['Approve Actions & Key Explanations']}
                                                                        params={['validated']}
                                                                        updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                                        />
                                                                </p>
                                                            :   ""
                                                    }
                                                    {(this.context.roles?.includes("ADMIN") || this.context.roles?.includes("TEAM"))
                                                        ? 
                                                            <SettingContentArrayCheckbox
                                                                dbUpdateLink={this.props.firebase.content(itemId)}
                                                                title={"Search Key Words"} 
                                                                content={content} 
                                                                params={['tags']}
                                                                selectOptions={[...(keyWordsDict?.tags || [])]}
                                                                noLabel={true}
                                                                sorting={true}
                                                                addManually={true}
                                                                updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                            />
                                                        :
                                                            <>
                                                                <div style={{...theme.textSupport, paddingLeft: 20}}>
                                                                    The key words help to find and search matching articles. Choose very specific keywords as 'smart goals' or 'pomodoro' that define the article. Words as 'stress' are usually detected by the AI intents.
                                                                </div>
                                                                <SettingContentArrayCheckbox
                                                                    dbUpdateLink={this.props.firebase.content(itemId)}
                                                                    title={"Search Key Words"} 
                                                                    content={content} 
                                                                    params={['tags']}
                                                                    selectOptions={[...(keyWordsDict?.tags || [])]}
                                                                    noLabel={true}
                                                                    sorting={true}
                                                                    addManually={true}
                                                                    updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                                />
                                                            </>
                                                    }
                                                    <h4>Program: {content.program}</h4>
                                                    <Grid container spacing={0} direction='row' alignItems="bottom" style={{width:"100%"}}>
                                                        <Grid item xs={6}>
                                                            <SettingContentSelect
                                                                dbUpdateLink={this.props.firebase.content(itemId)}
                                                                title={"Choose the Program"} 
                                                                content={content} 
                                                                label={['Program']}
                                                                params={['leadership_category']}
                                                                noLabel={true}
                                                                selectOptions={this.state.relatedCategories}
                                                                selectOptionsText={this.state.relatedCategoriesText}
                                                                updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                                />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <SettingContentArrayCheckbox
                                                                dbUpdateLink={this.props.firebase.content(itemId)}
                                                                title={"Select the related Modules"} 
                                                                content={content} 
                                                                label={['Modules']}
                                                                params={['leadership_quality']}
                                                                noLabel={true}
                                                                selectPreOpen={true}
                                                                selectOptions={this.state.qualitiesValues}
                                                                selectOptionsText={this.state.qualitiesText}
                                                                selectOptionsGroups={this.state.leadershipQualitiesGroups}
                                                                selectOptionsGroupsText={this.state.leadershipQualitiesGroupsText}
                                                                updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                            />
                                                            {content.topicTitle !== undefined && content.topicTitle !== '' &&  
                                                                <p>Original Quest: <strong>{content.topicTitle}</strong></p>
                                                            }
                                                        </Grid>
                                                    </Grid>
                                                    <h4>Visibility Filters</h4>
                                                    <Grid container spacing={0} direction='row' alignItems="bottom" style={{width:"100%"}}>
                                                        <Grid item xs>
                                                            <SettingContentSelect
                                                                dbUpdateLink={this.props.firebase.content(itemId)}
                                                                title={"Sentiment preference"} 
                                                                content={content} 
                                                                params={['sentiment']}
                                                                noLabel={true}
                                                                showButtons={true}
                                                                emptyStage={"For every sentiment"}
                                                                selectOptionsText={['positive - previous response positive', 'neutral - can be always asked', 'negative - previous response negative' ]}
                                                                selectOptions={['positive', 'default', 'negative']}
                                                                updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                            />
                                                        </Grid>
                                                        <Grid item xs>
                                                            <SettingContentArrayCheckbox
                                                                dbUpdateLink={this.props.firebase.content(itemId)}
                                                                title={"Required user life-stage"} 
                                                                content={content} 
                                                                noLabel={true}
                                                                params={['requirements']}
                                                                showLifeStageButtons={true}
                                                                emptyStage={"Every Life-Stage"}
                                                                selectOptions={['default', 'executive', 'professional', 'seeker', 'student', 'teenager']}
                                                                selectOptionsText={['Question for every life-stage', 'Leader/Entrepreneur', 'Professional/Employed', 'Available/Job-seeker', 'Student (Uni)', 'K-12/Teenager' ]}
                                                                updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                            /> 
                                                        </Grid>
                                                    </Grid>
                                                    <h4>References</h4>
                                                    <SettingContentBase 
                                                        dbUpdateLink={this.props.firebase.content(itemId)}
                                                        title={"Source and Links"} 
                                                        content={content} 
                                                        labels={['Name of source (e.g. HBR)', 'Title of Book/Article', 'External URL to author/book (https://www)', 'Optional: Amazon Book URL (https://www)']}
                                                        params={['source', 'sourceTitle', 'sourceUrl', 'amazonUrl']}
                                                        updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                    />
                                                    {/***
                                                    <SettingContentMapSelect 
                                                        dbUpdateLink={this.props.firebase.content(itemId)}
                                                        title={"Resources/External Links"} 
                                                        content={content} 
                                                        labels={['']}
                                                        params={['resources']}
                                                        updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                        />
                                                        */}
                                                    <SettingContentMapSelect
                                                        dbUpdateLink={this.props.firebase.content(itemId)}
                                                        title={"Optional: Promote article on a weekday"} 
                                                        content={content} 
                                                        params={['promotion']}
                                                        noLabel={true}
                                                        mapOptions={['weekday']}
                                                        mapOptionsText={['Day of promotion']}
                                                        selectOptionsText={['No special day', 'Monday', 'Tuesday','Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday' ]}
                                                        selectOptions={['default', 'monday', 'tuesday','wednesday', 'thursday', 'friday', 'saturday', 'sunday']}
                                                        updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                    />
                                                    <Accordion TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-label="Interactive"
                                                            aria-controls="Interactive"
                                                            id="Interactive"
                                                        >
                                                            &nbsp;Optional: Interactive Content
                                                        </AccordionSummary>
                                                        <AccordionDetails> 
                                                            <div style={{border:1}}>
                                                                <Grid item xs={12}>
                                                                    <SettingContentMapList
                                                                        dbUpdateLink={this.props.firebase.content(itemId)}
                                                                        title={"Optional: Interactive Questions"} 
                                                                        content={content} 
                                                                        params={['interactiveQuestions']}
                                                                        noLabel={true}
                                                                        mapOptions={['question', 'description']}
                                                                        mapOptionsText={['Question', 'Description (Optional)']}
                                                                        updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                                    />
                                                                </Grid>
                                                                {content?.interactiveQuestions?.length > 0 &&
                                                                    <Grid item xs={12}>
                                                                        <SettingContentActive 
                                                                            dbUpdateLink={this.props.firebase.content(itemId)}
                                                                            title={"Optional: Review Responses"} 
                                                                            content={content} 
                                                                            labels={['Goal setting review', 'Coach review or test']}
                                                                            params={['isGoalSetting', 'isReviewable']}
                                                                            updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                                        />
                                                                    </Grid>
                                                                }
                                                                <Grid item xs>
                                                                    <SettingContentActive 
                                                                        dbUpdateLink={this.props.firebase.content(itemId)}
                                                                        title={"Optional: Advanced interaction filters"} 
                                                                        content={content} 
                                                                        labels={['Deactivate Community Feature', 'Part of a course']}
                                                                        params={['isNonCommunity', 'isCourse']}
                                                                        updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs>
                                                                    <SettingContentSelect
                                                                        dbUpdateLink={this.props.firebase.content(itemId)}
                                                                        title={"Optional: Required XP level to unlock Content"} 
                                                                        content={content}
                                                                        noLabel={true}
                                                                        emptyOption={true}
                                                                        emptyStage={"Always accessible"}
                                                                        selectOptions={[0, 50, 100, 200, 300, 400, 600, 800, 1000]}
                                                                        selectOptionsText={["0% of first Module (0XP)", "25% of first Module (50XP)", "50% of first Module (100XP)", "100% of first Module (200XP)", "50% of second Module (300XP)", "Two modules completed (400XP)", "Three modules completed (600XP)", "Four modules completed (800XP)", "Five modules completed (1000XP)"]}
                                                                        params={['unlockXP']}
                                                                    />
                                                                </Grid>
                                                            </div>
                                                        </AccordionDetails> 
                                                    </Accordion>
                                                    <Accordion TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-label="AI"
                                                            aria-controls="AI"
                                                            id="AI"
                                                            >
                                                            {content.analyzed !== true && this.state['startedAITagging_'+itemId]
                                                                &&
                                                                <CircularProgress size={26} style={{color:"red"}} /> 
                                                            }
                                                            &nbsp;Advanced: AI Data Intents
                                                        </AccordionSummary>
                                                        <AccordionDetails> 
                                                            <div style={{border:1}}>
                                                                <p>AI Content</p>
                                                                {content.analyzedDate !== undefined &&
                                                                    <strong style={{color:"red"}}>last update: {ConvertTimestampToDateString(content.analyzedDate, true)}</strong>
                                                                }
                                                                <li>After the AI generated content it needs to be qualified and improved manually!</li>
                                                                <li>AI labels will be used to find the article - Remove irrelevant ones.</li>
                                                                <li>Key words will be used to find the article and rank the search match - Remove irrelevant ones.</li>
                                                                
                                                                {keyWordsDict?.utteranceDescriptions?.utter?.length > 1
                                                                    ?
                                                                        <>
                                                                            <p>These are AI labels used by Rocky for NLU (understanding the user intention). Choose about 5 examples that are most closely matching to the content.</p>
                                                                            <SettingContentArrayCheckbox
                                                                                dbUpdateLink={this.props.firebase.content(itemId)}
                                                                                title={"Remove false AI intentions and keep only those ones that match!"} 
                                                                                content={content}
                                                                                noLabel={true}
                                                                                showIntentDetails = {true}
                                                                                params={['utteranceClass']}
                                                                                labels={['AI label intentions']}
                                                                                groupFilter={['balance', 'self', 'mindset', 'learning', 'productivity', 'work', 'career', 'success', 'business', 'people', 'communication']}
                                                                                selectOptions={keyWordsDict?.utteranceDescriptions?.utter}
                                                                                selectOptionsText={keyWordsDict?.utteranceDescriptions?.response}
                                                                                updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                                                />
                                                                        </>
                                                                    :
                                                                        this.context.roles?.includes("ADMIN")
                                                                        ?
                                                                            <SettingContentArray
                                                                                dbUpdateLink={this.props.firebase.content(itemId)}
                                                                                title={"Manage AI labels"} 
                                                                                content={content}
                                                                                noLabel={true}
                                                                                params={['utteranceClass']}
                                                                                />
                                                                        : ""
                                                                }
        
                                                                {content.intentTags !== undefined
                                                                    &&
                                                                        <>
                                                                            <p>These are the keys of the AI labels used by Rocky for recommending articles to AI intents. Deactivate those that are irrelevant.</p>
                                                                            <SettingContentMapSelect
                                                                                dbUpdateLink={this.props.firebase.content(itemId)}
                                                                                title={"Active AI label tags used for the chatbot inspiration."} 
                                                                                content={content}
                                                                                params={['intentTags']}
                                                                                labels={['AI label keywords']}
                                                                                mapOptions={Object.keys(content.intentTags)}
                                                                                mapOptionsText={Object.keys(content.intentTags)}
                                                                                selectOptions={[false, true]}
                                                                                selectOptionsText={['inactive', 'active']}
                                                                                />
                                                                        </>
                                                                }
                                                                <hr/>
                                                                {content.analyzed !== true && this.state['startedAITagging_'+itemId]
                                                                    &&
                                                                    <span style={{color:"red"}}>Please wait 2 minutes until data is generated!</span>
                                                                }
                                                                {content.analyzed  &&
                                                                    <DialogKeyWords
                                                                        contentId={itemId}
                                                                        contentTags = {keyWordsDict?.tags}
                                                                        selectedCategory= {[content.leadership_category]}
                                                                        selectedQualities= {content.leadership_quality}
                                                                        leadershipQualities = {this.props.leadershipQualities}
                                                                        leadershipCategories={this.props.leadershipCategories}
                                                                        explanations = {keyWordsDict?.explanation}
                                                                        actions = {keyWordsDict?.actions}
                                                                    />
                                                                }
                                                                <hr/>
                                                                <Button style={ {backgroundColor : (content.analyzed || this.state['startedAITagging_'+itemId] )? "grey" : "red" }} 
                                                                    onClick={() => this.startAITagging(itemId, true)}
                                                                >
                                                                    Generate lables and questions by AI
                                                                </Button>
                                                                <br/>The AI button will update the tags and short explanations. Please remove unnecessary tags after AI sample creation! 
        
                                                            </div>
                                                        </AccordionDetails>
                                                    </Accordion>     

                                                </>
                                            }
                                        </>
                                    }
                                    <Accordion TransitionProps={{ mountOnEnter: true, unmountOnExit: true }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-label="extra"
                                            aria-controls="extra"
                                            id="extra"
                                            >
                                            &nbsp;Content Admin
                                        </AccordionSummary>
                                        <AccordionDetails> 
                                            <Grid container spacing={0} direction='row' alignItems="center" style={{width:"100%"}}>
                                                <Grid item xs={3}>
                                                    {content.active === false &&
                                                        <Button onClick={() => {
                                                            this.props.firebase.content(content.qid).delete();
                                                            this.handleClose();
                                                        }}>
                                                            DELETE Article
                                                        </Button>
                                                    }
                                                </Grid>
                                                <Grid item xs={9}>
                                                    <div style={{border:1}}>
                                                        {(this.context?.roles?.includes("ADMIN") || this.context?.roles?.includes("ADMIN") )
                                                        &&
                                                            <h4 style={{color :'blue'}}>Unique ID value: <strong>{content.qid}</strong></h4>  
                                                        }
                                                        <p>
                                                            createdAt: { ConvertTimestampToDateString(content.createdAt, true)} <br/>
                                                            updatedAt: { ConvertTimestampToDateString(content.updatedAt, true)} 
                                                        </p>
                                                        {false &&
                                                            <SettingContentActive 
                                                                dbUpdateLink={this.props.firebase.content(itemId)}
                                                                title={"Active Content"} 
                                                                content={content} 
                                                                labels={['Active in the app']}
                                                                infoText={["Active Content cannot be deleted"]}
                                                                params={['active']}
                                                                updateExtraChange={{'author' : this.props.firebase.fieldValue.arrayUnion(this.context.email), 'updatedAt' : this.props.firebase.fieldValue.serverTimestamp()}}
                                                                />
                                                        }
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                    {content && content.type !== "quotes" && 
                                        <Popper 
                                            id={'contentSum'} 
                                            open={!this.state.visibility} 
                                            anchorEl={this.anchorRef} 
                                            onClick={() => this.setState({visibility: true})}
                                        > 
                                            <div style={{maxWidth: 560, minWidth: 300, backgroundColor:'#fcd750', margin:20, padding: 20}}>
                                                <div>
                                                    {content?.youtubeId?.length > 2
                                                    && 
                                                        <>
                                                            <div 
                                                                style={{
                                                                    marginRight: 0,
                                                                    marginLeft: -20,
                                                                    marginTop: 10,
                                                                    width: 'calc(100% + 40px)',
                                                                }}
                                                            >
                                                                {
                                                                    <RenderMediaYoutube
                                                                        contentData = {content}
                                                                        enabled = {false}
                                                                    />
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                    {content.mediaType !== undefined && ['video'].includes(content.mediaType)
                                                    && content.s3Url !== undefined
                                                    && 
                                                        <>
                                                            <div 
                                                                style={{
                                                                    marginRight: 0,
                                                                    marginLeft: -20,
                                                                    marginTop: 10,
                                                                    width: 'calc(100% + 40px)',
                                                                }}
                                                            >
                                                                {
                                                                    <RenderMediaVideo
                                                                        contentData = {content}
                                                                        enabled = {false}
                                                                    />
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                    {content.mediaType !== undefined && ['audio'].includes(content.mediaType)
                                                    && content.s3Url !== undefined
                                                    && 
                                                        <>
                                                            <div 
                                                                style={{
                                                                    marginRight: 0,
                                                                    marginLeft: -20,
                                                                    marginTop: 10,
                                                                    width: 'calc(100% + 40px)',
                                                                }}
                                                            >
                                                                {
                                                                    <RenderMediaAudio
                                                                        contentData = {content}
                                                                        enabled = {false}
                                                                    />
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                                <div 
                                                    className={classes.innerHTML}
                                                    dangerouslySetInnerHTML={{ __html: "<strong style='color : green'><h3>" + content.title + "</h3><br/><br/>" + content.subtitle + "</strong><br/><br/>" + content.text }}
                                                >
                                                </div>
                                            </div>
                                        </Popper>
                                    }
                                </div>
                            : <div>Loading</div> 
                        }
                    </DialogContent>
                </Dialog>
                {(this.state.visibility || (content && content?.type !== "quotes")) 
                && 
                    <Dialog 
                        id={'contentSum'} 
                        key={'contentSum'} 
                        open={this.state.visibility || false} 
                        onClose={() => this.setState({visibility: false})}
                        //onMouseLeave={ () => {if(this._isMounted) this.setState({visibility : false})}}
                        PaperProps={{style: {...theme.dialogWindow, position: "absolute", left: 0}}}
                    > 
                        <DialogTitle style={theme.dialogTitle}  >
                            <Grid container spacing={0} direction='row' justify="space-between" >
                                <Grid item >
                                    <IconButton onClick={() => this.setState({visibility: false})}>
                                        <ArrowBackIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item >
                                    <span style={theme.dialogTitle.titleText}>
                                        Article Preview
                                    </span>
                                </Grid>
                                <Grid item >
                                    {this.state.openContentSettings !== true && content?.title?.length > 2
                                    &&
                                        <Button size="small" 
                                            onClick={() => { this.copyToNewContent(content, this.state.activeKey?.replace('showKey_', ""), selectedCategoryKey, this.state.activeQualityKey?.replace(this.state.activeKey, "")) }}>
                                            Copy into Quest
                                        </Button>
                                    }
                                </Grid>
                            </Grid>
                        </DialogTitle>
                        {this.state.visibility && content
                        &&
                            <DialogContent>
                                <div>
                                    {content?.youtubeId?.length > 2
                                    && 
                                        <>
                                            <div 
                                                style={{
                                                    marginRight: 0,
                                                    marginLeft: -20,
                                                    marginTop: 10,
                                                    width: 'calc(100% + 40px)',
                                                }}
                                            >
                                                {
                                                    <RenderMediaYoutube
                                                        contentData = {content}
                                                    />
                                                }
                                            </div>
                                        </>
                                    }
                                    {content?.mediaType !== undefined && ['video'].includes(content.mediaType)
                                    && content.s3Url !== undefined
                                    && 
                                        <>
                                            <div 
                                                style={{
                                                    marginRight: 0,
                                                    marginLeft: -20,
                                                    marginTop: 10,
                                                    width: 'calc(100% + 40px)',
                                                }}
                                            >
                                                {
                                                    <RenderMediaVideo
                                                        contentData = {content}
                                                    />
                                                }
                                            </div>
                                        </>
                                    }
                                    {content.mediaType !== undefined && ['audio'].includes(content.mediaType)
                                    && content.s3Url !== undefined
                                    && 
                                        <>
                                            <div 
                                                style={{
                                                    marginRight: 0,
                                                    marginLeft: -20,
                                                    marginTop: 10,
                                                    width: 'calc(100% + 40px)',
                                                }}
                                            >
                                                {
                                                    <RenderMediaAudio
                                                        contentData = {content}
                                                    />
                                                }
                                            </div>
                                        </>
                                    }
                                </div>
                                <p>
                                    <strong style={{color : 'green'}}>
                                        <h3>{ content.title}</h3><br/><br/>
                                        {content.subtitle} 
                                    </strong>
                                    <br/><br/>
                                    {(content.source || "") + " " + (content.sourceTitle || "")}
                                </p>
                                {content?.contentImage?.includes('http')
                                &&
                                    <div style={{width: '100%', textAlign: 'center'}}>
                                        <img src={content.contentImage} style={{width: '100%', maxWidth: 400}} alt="Example"/>
                                    </div>
                                }
                                <div 
                                    className={classes.innerHTML}
                                    onClick = {(event) => {
                                        try{
                                            const link = event.target.getAttribute('href');
                                            if(link !== undefined && link !== null && link !== ""){
                                                event.preventDefault();
                                                window.open(link, "_blank")
                                            }
                                        }
                                        catch(e){
                                            console.log('No Link', e)
                                        }
                                    }}
                                    dangerouslySetInnerHTML={
                                        { __html: "" + content.text }}>
                                </div>
                            </DialogContent>
                        }
                    </Dialog>
                }
            </RemoveScroll>
        )
    }

    updateAIGroup = (contentIds, key, subKey) => {
        this.setState({['loading_AI_'+key+subKey] : true})
        contentIds.map( (itemId, index) => {
            //console.log(itemId, key, subKey);
            setTimeout(() => {
                this.startAITagging(itemId)
                if(index === contentIds.length-1 ){
                    this.setState({['loading_AI_'+key+subKey] : false})
                }
            }, index*20*1000 )
            return true;
        })
    }

    resetAIGroup(contentIds, key, subKey){
        contentIds.map( itemId => {
            //console.log(itemId, key, subKey);
            this.removeUtterancesActions(itemId);
            return true;
        })
    }

    startAITagging = (itemId, overwrite= false) => {
        //console.log(itemId, 'started');
        if(overwrite){
            this.props.firebase.content(itemId).update({active: true, analyzedDate: this.props.firebase.fieldValue.delete()});
        }
        else{
            //this.props.firebase.content(itemId).update({active: true});
        }
        this.props.firebase.content(itemId + "/postprocess/searchKeyWords" )
        .set({triggerStartSearch: false})
        .then( () => {
            this.props.firebase.content(itemId + "/postprocess/searchKeyWords" ).set({triggerStartSearch: true});
            var key = 'startedAITagging_'+itemId
            this.setState({ [key] : true})
        })
    }

    removeUtterancesActions = (itemId) => {
        var resetSet = {
            validated : false,
            analyzed: false,
            analyzedDate: this.props.firebase.fieldValue.delete(),
            actions: [],
            explanation: [],
            intentCategory: {},
            intentTags: {},
            utteranceClass: []
        }
        console.log('reset', itemId)
        this.props.firebase.content(itemId).update(resetSet);
    }

}//end class


export default compose(
    withFirebase,
    withStyles(styles, { withTheme: true })
)(ManageContent);
